import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';

type Children = (
	methods: UseFormReturn<FieldValues, object>
) => React.ReactElement;

export const ConnectForm = ({ children }: { children: Children }) => {
	const methods = useFormContext();

	return children({ ...methods });
};
