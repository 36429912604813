import { useContext } from 'react';
import { ApplicationContext } from '../../../contexts/ApplicationContext';

export const Unavailable = () => {
	const { customization } = useContext(ApplicationContext);
	return (
		<div
			className="container d-flex align-items-center justify-content-center"
			style={{ height: '100vh', width: '600px' }}
		>
			<div className="card card-custom my-auto">
				<div className="card-body">
					<div className="text-center">
						<h1 className="display-3 text-muted font-weight-bold">
							😢
						</h1>
						<h1 className="font-size-h3 text-dark-75 font-weight-bold">
							Ops!
						</h1>
						<h1 className="font-size-h3 text-dark-75 font-weight-bold">
							Parece que o checkout não está configurado
						</h1>
						<p className="text-muted font-size-h5 font-weight-bold">
							Contate o administrador da plataforma para maiores
							informações.{' '}
							<a
								className="text-primary"
								href={customization?.url}
							>
								Clique aqui para acessar a plataforma
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
