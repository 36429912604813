import { CreditCardForm } from '../CreditCardForm';

import { BarcodeReadIcon } from '../../../../components/icons/BarcodeReadIcon';
import { TimerIcon } from '../../../../components/icons/TimerIcon';
import { LikeIcon } from '../../../../components/icons/LikeIcon';
import { ShieldProtectedIcon } from '../../../../components/icons/ShieldProtected';
import { Barcode } from '../../../../components/icons/BarcodeIcon';
import { CreditCardIcon } from '../../../../components/icons/CreditCardIcon';

import { Row, Col, Tabs, Tab, Card } from 'react-bootstrap';
import { PlanContext } from '../Context/PlanContext';
import { ContentContext } from '../Context/ContentContext';
import { useContext, useEffect, useState } from 'react';
import { BillingType } from '../constants/billingType';

type BillingTypeFormProps = {
	setBillingType: (value: BillingType) => void;
};

export const BillingTypeForm = ({ setBillingType }: BillingTypeFormProps) => {
	const queryParams = new URLSearchParams(window.location.search);

	const [method, setMethod] = useState(queryParams.get('method') || '');

	const [onlyOnePaymentMethod, setOnlyOnePaymentMethod] = useState(false);

	const { plan } = useContext(PlanContext);
	const { content } = useContext(ContentContext);

	useEffect(() => {
		if (plan) return setOnlyOnePaymentMethod(true);

		const isOnlyOnePaymentMethodAvailable = [
			content?.pricing.isCreditCardEnabled,
			content?.pricing.isPixEnabled,
			content?.pricing.isTicketEnabled
		];

		setOnlyOnePaymentMethod(
			isOnlyOnePaymentMethodAvailable.filter(Boolean).length === 1
		);
	}, [content, plan]);

	useEffect(() => {
		if (!content) return;

		const isPix = method === 'pix';
		const isBoleto = method === 'boleto';

		if (content?.pricing.isCreditCardEnabled && !isPix && !isBoleto)
			return setBillingType(BillingType.CreditCard);

		if (content?.pricing.isTicketEnabled && !isPix)
			return setBillingType(BillingType.Boleto);

		if (content?.pricing.isPixEnabled)
			return setBillingType(BillingType.Pix);
	}, [content, method]);

	return (
		<>
			<Card.Title className="card-title mt-5 mt-md-8">
				<h3 className="card-label h4 text-gray-800">
					Método de pagamento
				</h3>
			</Card.Title>
			<Tabs
				variant="tabs"
				defaultActiveKey={method || 'cc'}
				activeKey={method || 'cc'}
				onSelect={(key) => setMethod(key || 'cc')}
				className="d-flex justify-content-between mx-0 mb-3 nav-bordered"
			>
				{(content?.pricing.isCreditCardEnabled || plan) && (
					<Tab
						title={
							<div
								className="d-flex align-items-center justify-content-center w-100"
								style={{ gap: '1rem' }}
							>
								<span className="svg-icon svg-icon-2x nav-icon">
									<CreditCardIcon />
								</span>
								<span className="nav-text font-size-md font-weight-bold mb-0">
									Cartão
								</span>
							</div>
						}
						eventKey="cc"
						tabClassName={`col ${
							onlyOnePaymentMethod && 'd-none'
						} ${
							(content?.pricing.isTicketEnabled ||
								content?.pricing.isPixEnabled) &&
							'mr-5'
						} `}
						onEnter={() => setBillingType(BillingType.CreditCard)}
					>
						<CreditCardForm />
					</Tab>
				)}
				{content?.pricing.isPixEnabled && (
					<Tab
						title={
							<div
								className="d-flex align-items-center justify-content-center w-100"
								style={{ gap: '1rem' }}
							>
								<span className="svg-icon svg-icon-2x nav-icon">
									<BarcodeReadIcon />
								</span>
								<span className="nav-text font-size-md font-weight-bold mb-0">
									Pix
								</span>
							</div>
						}
						eventKey="pix"
						tabClassName={`col ml-3 ${
							onlyOnePaymentMethod && 'd-none'
						}`}
						onEnter={() => setBillingType(BillingType.Pix)}
					>
						<Row className="mt-5 text-gray-600">
							<Col md={4} className="text-center mt-5">
								<span className="svg-icon svg-icon-primary svg-icon-4x">
									<TimerIcon />
								</span>
								<h4>Imediato</h4>
								<p>
									Faça seu pagamento em poucos segundos sem
									custos adicionais
								</p>
							</Col>
							<Col md={4} className="text-center mt-5">
								<span className="svg-icon svg-icon-primary svg-icon-4x">
									<LikeIcon />
								</span>
								<h4>Simples</h4>
								<p>
									Para pagar basta abrir o aplicativo do seu
									banco, procurar pelo PIX e escanear o QRcode
								</p>
							</Col>
							<Col md={4} className="text-center mt-5">
								<span className="svg-icon svg-icon-primary svg-icon-4x">
									<ShieldProtectedIcon />
								</span>
								<h4>Seguro</h4>
								<p>
									O pagamento com PIX foi desenvolvido pelo
									Banco Central para facilitar suas compras
								</p>
							</Col>
						</Row>
					</Tab>
				)}
				{content?.pricing.isTicketEnabled && (
					<Tab
						title={
							<div
								className="d-flex align-items-center justify-content-center w-100"
								style={{ gap: '1rem' }}
							>
								<span className="svg-icon svg-icon-2x nav-icon">
									<Barcode />
								</span>
								<span className="nav-text font-size-md font-weight-bold mb-0">
									Boleto
								</span>
							</div>
						}
						eventKey="boleto"
						tabClassName={`col mx-3 ${
							onlyOnePaymentMethod && 'd-none'
						}`}
						onEnter={() => setBillingType(BillingType.Boleto)}
					>
						<div className="mb-10 text-gray-600">
							<h4 className="h6 mt-8 mb-5">Atenção:</h4>
							<ul className="pl-5">
								<li>Boleto</li>
								<li>
									Pagamentos com Boleto Bancário levam até 3
									dias úteis para serem compensados e então
									terem os produtos liberados
								</li>
								<li>
									Atente-se ao vencimento do boleto. Você pode
									pagar o boleto em qualquer banco ou casa
									lotérica até o dia do vencimento
								</li>
							</ul>
						</div>
					</Tab>
				)}
			</Tabs>
		</>
	);
};
