import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';
import { FinanceParams } from './types/applications.types';

const getFinanceParams: QueryFunction<FinanceParams> = async () => {
	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<FinanceParams>(`/applications/params`);

	return response.data;
};

export const applicationsApi = {
	getFinanceParams: {
		key: 'GET_FINANCE_PARAMS',
		fetch: getFinanceParams
	}
};
