import { createContext } from 'react';
import { IPlan } from '../../../../api/plans/interfaces/planInterface';

interface IPlanContext {
	plans?: IPlan[];
	setPlan: (value: IPlan) => void;
	plan?: IPlan;
}

export const PlanContext = createContext<IPlanContext>({
	setPlan: () => false
});
