import { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { ITextInputProps } from '../TextInputPropsInterface';
import TextField from '@mui/material/TextField';

const PhoneInput = forwardRef(
	({ onChange, onBlur, ...props }: ITextInputProps, ref) => {
		return (
			<InputMask
				mask="99 99 999999999"
				onChange={onChange}
				onBlur={onBlur}
				defaultValue={'55'}
			>
				<TextField
					inputRef={ref}
					label="Celular"
					type="text"
					placeholder="Seu celular"
					{...props}
				/>
			</InputMask>
		);
	}
);

PhoneInput.displayName = 'PhoneInput';

export { PhoneInput };
