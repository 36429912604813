import TextField from '@mui/material/TextField';
import {
	Row,
	Card as BsCard,
	Spinner,
	Form,
	Col,
	Button
} from 'react-bootstrap';
import Card from '@mui/material/Card';

import { Controller } from 'react-hook-form';
import { CpfCnpjInput } from '../../components/Inputs/CpfCnpjInput';
import {
	CreditCardExpirationInput,
	CreditCardNumberInput,
	HolderNameInput
} from '../../components/Inputs/CreditCardInput';
import { isValidDocument } from '../../utils/validators/document';
import { isUrl } from '../../utils/validators/url';
import { useUpdateSubscriptionCard } from './use-update-subscription-card';
import { PhoneInput } from '../../components/Inputs/PhoneInput';
import { formatCurrency } from '../../utils/formatCurrency';

export const UpdateSubscriptionCard = () => {
	const {
		isLoading,
		handleSubmit,
		register,
		control,
		errors,
		isSubmitting,
		hasCreated,
		handleFetchCep,
		onSubmit,
		customization,
		subscription
	} = useUpdateSubscriptionCard();

	if (isLoading) {
		return (
			<div className="content" style={{ height: '100vh' }}>
				<div className="container h-100 d-flex align-items-center justify-content-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			</div>
		);
	}

	if (hasCreated) {
		return (
			<div className="content" style={{ height: '100vh' }}>
				<div className="container h-100 d-flex align-items-center justify-content-center">
					<Card className="col-md-6 card-body">
						<BsCard.Header className="d-flex flex-column bg-transparent">
							<div className="d-flex flex-column justify-content-between align-items-center">
								<h2 className="h3 mb-0 text-gray-800 text-center">
									Cartão atualizado com sucesso!
								</h2>

								<p className="mb-0 text-gray-800 text-center mt-2">
									Você já pode fechar a página.
								</p>
							</div>
						</BsCard.Header>
					</Card>
				</div>
			</div>
		);
	}

	return (
		<div className="content" style={{ height: '100vh' }}>
			<div className="container h-100 d-flex align-items-center justify-content-center">
				<Card className="col-md-6 card-body">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BsCard.Header className="d-flex flex-column bg-transparent">
							<div className="d-flex justify-content-between align-items-center">
								<h2 className="h3 mb-0 text-gray-800">
									Atualizar cartão da assinatura
								</h2>
								{customization && (
									<img
										style={{ width: '100%', maxWidth: 200 }}
										src={
											isUrl(customization?.logo)
												? customization?.logo
												: `${customization?.url}${
														customization?.logo
												  }?d=${Date.now()}`
										}
									/>
								)}
							</div>

							<div className="d-flex flex-column mt-5 text-gray-800">
								<span>
									Plano:{' '}
									<strong>{subscription?.plan?.name}</strong>
								</span>
								<span>
									Valor:{' '}
									<strong>
										{formatCurrency(
											subscription?.billing?.grossPrice ||
												0
										)}
									</strong>
								</span>
							</div>
						</BsCard.Header>
						<BsCard.Body>
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="h6 mb-5 card-label text-gray-800">
									Dados pessoais
								</h4>
							</div>
							<Row>
								<Col className="mb-5 pr-md-1" lg={12}>
									<CpfCnpjInput
										{...register('cpfCnpj', {
											required:
												'O campo de documento é obrigatório!',
											validate: isValidDocument
										})}
										isForeign={false}
										error={!!errors.cpfCnpj}
										helperText={
											errors.cpfCnpj?.type === 'validate'
												? 'Documento inválido'
												: errors.cpfCnpj?.message
										}
										required
									/>
								</Col>
								<Col className="mb-5" lg={12}>
									<PhoneInput
										required
										className="w-100"
										type="text"
										{...register('phone', {
											required:
												'O campo de celular é obrigatório!'
										})}
										error={!!errors.phone}
										helperText={errors.phone?.message}
									/>
								</Col>
							</Row>
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="h6 my-5 text-gray-800">
									Endereço
								</h4>
							</div>
							<Row>
								<Col md={3} className="mb-5 pr-md-1">
									<TextField
										type="text"
										{...register('address.postalcode', {
											required:
												'O campo de CEP é obrigatório!'
										})}
										placeholder="Digite apenas números"
										label="CEP"
										error={!!errors.address?.postalcode}
										helperText={
											errors.address?.postalcode?.message
										}
										onBlur={(e) =>
											handleFetchCep(
												e.currentTarget.value
											)
										}
										className="w-100 pr-1"
									/>
								</Col>
								<Col className="mb-5 pl-md-1" md={9}>
									<Controller
										control={control}
										name="address.address"
										rules={{
											required:
												'O campo de endereço é obrigatório!'
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												value={value}
												onChange={onChange}
												type="text"
												label="Endereço"
												placeholder="Avenida Paulista"
												error={
													!!errors.address?.address
												}
												helperText={
													errors.address?.address
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>

								<Col className="mb-5 pr-md-1" xs={4} lg={2}>
									<TextField
										type="text"
										{...register('address.number', {
											required:
												'O campo de número de endereço é obrigatório!',
											pattern: {
												message:
													'O campo de número de endereço deve conter somente números!',
												value: /[0-9]+/
											},
											valueAsNumber: true
										})}
										placeholder="Digite apenas números"
										label="Número"
										error={!!errors.address?.number}
										helperText={
											errors.address?.number?.message
										}
										className="w-100"
									/>
								</Col>
								<Col className={'mb-5 px-md-1'} md={5} xs={8}>
									<TextField
										type="text"
										label="Complemento"
										{...register('address.complement')}
										placeholder="AP 44 Bloco B"
										error={!!errors.address?.complement}
										helperText={
											errors.address?.complement?.message
										}
										className="w-100"
									/>
								</Col>
								<Col className="mb-5 pl-lg-1" md={5}>
									<Controller
										control={control}
										name="address.province"
										rules={{
											required:
												'O campo de bairro é obrigatório!'
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												value={value}
												onChange={onChange}
												type="text"
												label="Bairro"
												placeholder="Pinheiros"
												error={
													!!errors.address?.province
												}
												helperText={
													errors.address?.province
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>
								<Col md={9} className={`mb-5 pr-md-1`}>
									<Controller
										control={control}
										name="address.city"
										rules={{
											required:
												'O campo de cidade é obrigatório!'
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												type="text"
												{...register('address.city', {
													required:
														'O campo de cidade é obrigatório!'
												})}
												value={value}
												onChange={onChange}
												label="Cidade"
												placeholder="São Paulo"
												error={!!errors.address?.city}
												helperText={
													errors.address?.city
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>
								<Col md={3} className={'mb-5 pl-md-1'}>
									<Controller
										control={control}
										name="address.state"
										rules={{
											required:
												'O campo de estado é obrigatório!',
											maxLength: {
												message:
													'O campo de estado deve conter no máximo de 2 caracteres!',
												value: 2
											}
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												type="text"
												onChange={onChange}
												value={value}
												placeholder="SP"
												label="Estado"
												error={!!errors.address?.state}
												helperText={
													errors.address?.state
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>

								<Col md={3} className={'mb-5 pl-md-1 d-none'}>
									<TextField
										label="País"
										className="w-100"
										type={'hidden'}
										defaultValue="BR"
										{...register('address.country')}
									/>
								</Col>
							</Row>
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="my-5 h4 text-gray-800">
									Dados do cartão de crédito
								</h4>
							</div>
							<Row>
								<Col xs={12} md={6} className="mb-5 pr-md-1">
									<CreditCardNumberInput
										className="w-100"
										{...register(`number`, {
											pattern: /[0-9]+/,
											required:
												'O campo de número do cartão é obrigatório!'
										})}
										error={!!errors.number}
										helperText={errors.number?.message}
									/>
								</Col>
								<Col md={6} className="mb-5 pl-md-1">
									<HolderNameInput
										type="text"
										className="w-100"
										{...register(`holderName`, {
											pattern: /[A-Za-z]+/,
											required:
												'O campo de nome impresso no cartão é obrigatório!'
										})}
										error={!!errors.holderName}
										helperText={errors.holderName?.message}
									/>
								</Col>
								<Col md={6} className="mb-5 pr-md-1">
									<CreditCardExpirationInput
										isForeign={false}
										className="w-100"
										type="text"
										{...register(`expiry`, {
											required:
												'O campo de expiração do cartão é obrigatório!'
										})}
										error={!!errors.expiry}
										helperText={errors.expiry?.message}
									/>
								</Col>
								<Col md={6} className="mb-5 pl-md-1">
									<TextField
										className="w-100"
										type="text"
										label="CVV*"
										{...register(`cvv`, {
											pattern: /[0-9]+/,
											required:
												'O campo de CVV do cartão é obrigatório!'
										})}
										placeholder="CVV"
										error={!!errors.cvv}
										helperText={errors.cvv?.message}
									/>
								</Col>
							</Row>
						</BsCard.Body>
						<BsCard.Footer className="d-flex justify-content-end bg-transparent">
							<Button
								type="submit"
								variant="primary"
								disabled={isSubmitting || hasCreated}
							>
								{isSubmitting && (
									<Spinner
										animation="border"
										size="sm"
										as="span"
										role="status"
										aria-hidden="true"
										className="mr-1 mb-1"
									/>
								)}
								Salvar
							</Button>
						</BsCard.Footer>
					</Form>
				</Card>
			</div>
		</div>
	);
};
