const BLACKLIST = [
	'00000000000000',
	'11111111111111',
	'22222222222222',
	'33333333333333',
	'44444444444444',
	'55555555555555',
	'66666666666666',
	'77777777777777',
	'88888888888888',
	'99999999999999'
];

export const isValidCnpj = (cnpj: string) => {
	const parsedCnpj = cnpj.replace(/\D/g, '');

	if (
		!parsedCnpj ||
		parsedCnpj.length != 14 ||
		BLACKLIST.includes(parsedCnpj)
	)
		return false;

	let size = parsedCnpj.length - 2;
	let numbers = parsedCnpj.substring(0, size);
	const digitos = parsedCnpj.substring(size);

	let soma = 0;
	let pos = size - 7;
	for (let i = size; i >= 1; i--) {
		soma += Number(numbers.charAt(size - i)) * pos--;
		if (pos < 2) pos = 9;
	}
	let result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (result !== Number(digitos.charAt(0))) return false;

	size = size + 1;
	numbers = parsedCnpj.substring(0, size);
	soma = 0;
	pos = size - 7;

	for (let i = size; i >= 1; i--) {
		soma += Number(numbers.charAt(size - i)) * pos--;
		if (pos < 2) pos = 9;
	}

	result = soma % 11 < 2 ? 0 : 11 - (soma % 11);

	if (result !== Number(digitos.charAt(1))) return false;

	return true;
};
