import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';

type Application = {
	name: string;

	integrations: {
		whatsappUrl: string;
	};

	customization: {
		primaryColor: string;
		secondaryColor: string;
		theme: {
			id: number;
			title: 'DARK' | 'LIGHT' | 'CUSTOM';
		};
		logo: string;
		url: string;
	};
};

const find: QueryFunction<Application> = async () => {
	const response = await (
		await apiFactory.getInstance().getCurseducaIntegrationsClient()
	).get<Application>(`/applications`);

	return response.data;
};

export const customizationsApi = {
	find: {
		key: 'FIND_APPLICATION_CUSTOMIZATION',
		fetch: find
	}
};
