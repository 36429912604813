import axios from 'axios';
import TextField from '@mui/material/TextField';
import { Row, Card, Spinner, Form, Col, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditCardsApi } from '../../api/creditCards';
import { CpfCnpjInput } from '../../components/Inputs/CpfCnpjInput';
import {
	CreditCardExpirationInput,
	CreditCardNumberInput,
	HolderNameInput
} from '../../components/Inputs/CreditCardInput';
import { useCep } from '../../utils/useCep';
import { CreateCreditCardInput } from './types/CreateCreditCardInput';
import { toast } from '../../utils/toast';
import { useSearchParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { isValidDocument } from '../../utils/validators/document';

import { PhoneInput } from '../../components/Inputs/PhoneInput';
import { customersApi } from '../../api/customers';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { isUrl } from '../../utils/validators/url';

export const CreateCreditCard = () => {
	const { customization, finance } = useContext(ApplicationContext);

	const [hasCreated, setHasCreated] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [searchParams] = useSearchParams();

	const tokenizeCreditCardApi = useMutation(creditCardsApi.tokenize);
	const createCreditCardApi = useMutation(creditCardsApi.create.fetch);
	const createCustomerApi = useMutation(customersApi.create);

	const methods = useForm<CreateCreditCardInput>();

	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors }
	} = methods;

	const onSubmit = async (creditCard: CreateCreditCardInput) => {
		setIsSubmitting(true);

		try {
			creditCard.cpfCnpj = creditCard.cpfCnpj.replace(/\D/g, '');
			creditCard.address.postalcode =
				creditCard.address.postalcode.replace(/\D/g, '');
			creditCard.number = creditCard.number.replaceAll(' ', '');
			const [expiryMonth, expiryYear] = creditCard.expiry.split('/');
			const [countryCode, areaCode, number] = creditCard.phone.split(' ');

			await createCustomerApi.mutateAsync({
				isForeign: false,
				authToken: searchParams.get('token') || undefined,
				cpfCnpj: creditCard.cpfCnpj,
				phones: {
					mobilePhone: {
						countryCode,
						areaCode,
						number
					}
				}
			});

			const { card, id } = await tokenizeCreditCardApi.mutateAsync({
				hasAntifraud: finance?.isAntifraudEnabled ?? true,
				card: {
					cvv: creditCard.cvv,
					holder_name: creditCard.holderName,
					number: creditCard.number,
					exp_month: Number(expiryMonth),
					exp_year: Number(expiryYear)
				},
				type: 'card'
			});

			await createCreditCardApi.mutateAsync({
				address: creditCard.address,
				cpfCnpj: creditCard.cpfCnpj,
				expiryMonth: Number(expiryMonth),
				expiryYear: Number(expiryYear),
				holderName: creditCard.holderName,
				flag: card.brand,
				number: card.last_four_digits,
				token: id,
				authToken: searchParams.get('token') || undefined
			});

			setHasCreated(true);

			return toast.success('Cartão criado com sucesso!');
		} catch (e) {
			if (axios.isAxiosError(e)) {
				if (e.response?.data.errors) {
					const index = Object.keys(e.response?.data.errors).find(
						Boolean
					);

					return index
						? toast.error(e.response.data.errors[index])
						: toast.error(
								'Houve um erro desconhecido ao tokenizar cartão.'
						  );
				}

				return toast.error(
					e.response?.data.message ||
						'Houve um erro desconhecido ao tokenizar cartão.'
				);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleFetchCep = async (cep: string) => {
		const { bairro, logradouro, localidade, uf } = await useCep.fetch(cep);

		setValue('address.province', bairro);
		setValue('address.city', localidade);
		setValue('address.state', uf);
		setValue('address.address', logradouro);
	};

	return (
		<div className="content" style={{ height: '100vh' }}>
			<div className="container h-100 d-flex align-items-center justify-content-center">
				<Card className="col-md-6">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h2 className="h3 mb-0">Cadastrar novo cartão</h2>
							{customization && (
								<img
									style={{ width: '100%' }}
									src={
										isUrl(customization?.logo)
											? customization?.logo
											: `${customization?.url}${
													customization?.logo
											  }?d=${Date.now()}`
									}
								/>
							)}
						</Card.Header>
						<Card.Body>
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="h6 mb-5">Dados pessoais</h4>
							</div>
							<Row>
								<Col className="mb-5 pr-md-1" lg={12}>
									<CpfCnpjInput
										{...register('cpfCnpj', {
											required:
												'O campo de documento é obrigatório!',
											validate: isValidDocument
										})}
										isForeign={false}
										error={!!errors.cpfCnpj}
										helperText={
											errors.cpfCnpj?.type === 'validate'
												? 'Documento inválido'
												: errors.cpfCnpj?.message
										}
										required
									/>
								</Col>
								<Col className="mb-5 pl-md-1" lg={6}>
									<PhoneInput
										required
										className="w-100"
										type="text"
										{...register('phone', {
											required:
												'O campo de celular é obrigatório!'
										})}
										error={!!errors.phone}
										helperText={errors.phone?.message}
									/>
								</Col>
							</Row>
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="h6 my-5">Endereço</h4>
							</div>
							<Row>
								<Col md={3} className="mb-5 pr-md-1">
									<TextField
										type="text"
										{...register('address.postalcode', {
											required:
												'O campo de CEP é obrigatório!'
										})}
										placeholder="Digite apenas números"
										label="CEP"
										error={!!errors.address?.postalcode}
										helperText={
											errors.address?.postalcode?.message
										}
										onBlur={(e) =>
											handleFetchCep(
												e.currentTarget.value
											)
										}
										size="small"
										className="w-100 pr-1"
									/>
								</Col>
								<Col className="mb-5 pl-md-1" md={9}>
									<Controller
										control={control}
										name="address.address"
										rules={{
											required:
												'O campo de endereço é obrigatório!'
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												value={value}
												onChange={onChange}
												size="small"
												type="text"
												label="Endereço"
												placeholder="Avenida Paulista"
												error={
													!!errors.address?.address
												}
												helperText={
													errors.address?.address
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>

								<Col className="mb-5 pr-md-1" xs={4} lg={2}>
									<TextField
										size="small"
										type="text"
										{...register('address.number', {
											required:
												'O campo de número de endereço é obrigatório!',
											pattern: {
												message:
													'O campo de número de endereço deve conter somente números!',
												value: /[0-9]+/
											},
											valueAsNumber: true
										})}
										placeholder="Digite apenas números"
										label="Número"
										error={!!errors.address?.number}
										helperText={
											errors.address?.number?.message
										}
										className="w-100"
									/>
								</Col>
								<Col className={'mb-5 px-md-1'} md={5} xs={8}>
									<TextField
										size="small"
										type="text"
										label="Complemento"
										{...register('address.complement')}
										placeholder="AP 44 Bloco B"
										error={!!errors.address?.complement}
										helperText={
											errors.address?.complement?.message
										}
										className="w-100"
									/>
								</Col>
								<Col className="mb-5 pl-lg-1" md={5}>
									<Controller
										control={control}
										name="address.province"
										rules={{
											required:
												'O campo de bairro é obrigatório!'
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												value={value}
												onChange={onChange}
												size="small"
												type="text"
												label="Bairro"
												placeholder="Pinheiros"
												error={
													!!errors.address?.province
												}
												helperText={
													errors.address?.province
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>
								<Col md={9} className={`mb-5 pr-md-1`}>
									<Controller
										control={control}
										name="address.city"
										rules={{
											required:
												'O campo de cidade é obrigatório!'
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												type="text"
												{...register('address.city', {
													required:
														'O campo de cidade é obrigatório!'
												})}
												value={value}
												onChange={onChange}
												label="Cidade"
												size="small"
												placeholder="São Paulo"
												error={!!errors.address?.city}
												helperText={
													errors.address?.city
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>
								<Col md={3} className={'mb-5 pl-md-1'}>
									<Controller
										control={control}
										name="address.state"
										rules={{
											required:
												'O campo de estado é obrigatório!',
											maxLength: {
												message:
													'O campo de estado deve conter no máximo de 2 caracteres!',
												value: 2
											}
										}}
										defaultValue=""
										render={({
											field: { value, onChange }
										}) => (
											<TextField
												type="text"
												onChange={onChange}
												value={value}
												placeholder="SP"
												size="small"
												label="Estado"
												error={!!errors.address?.state}
												helperText={
													errors.address?.state
														?.message
												}
												className="w-100"
											/>
										)}
									/>
								</Col>

								<Col md={3} className={'mb-5 pl-md-1 d-none'}>
									<TextField
										size="small"
										label="País"
										className="w-100"
										type={'hidden'}
										defaultValue="BR"
										{...register('address.country')}
									/>
								</Col>
							</Row>
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="my-5 h4 text-gray-800">
									Dados do cartão de crédito
								</h4>
							</div>
							<Row>
								<Col xs={12} md={6} className="mb-5 pr-md-1">
									<CreditCardNumberInput
										size="small"
										className="w-100"
										{...register(`number`, {
											pattern: /[0-9]+/,
											required:
												'O campo de número do cartão é obrigatório!'
										})}
										error={!!errors.number}
										helperText={errors.number?.message}
									/>
								</Col>
								<Col md={6} className="mb-5 pl-md-1">
									<HolderNameInput
										size="small"
										type="text"
										className="w-100"
										{...register(`holderName`, {
											pattern: /[A-Za-z]+/,
											required:
												'O campo de nome impresso no cartão é obrigatório!'
										})}
										error={!!errors.holderName}
										helperText={errors.holderName?.message}
									/>
								</Col>
								<Col md={6} className="mb-5 pr-md-1">
									<CreditCardExpirationInput
										isForeign={false}
										className="w-100"
										size="small"
										type="text"
										{...register(`expiry`, {
											required:
												'O campo de expiração do cartão é obrigatório!'
										})}
										error={!!errors.expiry}
										helperText={errors.expiry?.message}
									/>
								</Col>
								<Col md={6} className="mb-5 pl-md-1">
									<TextField
										size="small"
										className="w-100"
										type="text"
										label="CVV*"
										{...register(`cvv`, {
											pattern: /[0-9]+/,
											required:
												'O campo de CVV do cartão é obrigatório!'
										})}
										placeholder="CVV"
										error={!!errors.cvv}
										helperText={errors.cvv?.message}
									/>
								</Col>
							</Row>
						</Card.Body>
						<Card.Footer className="d-flex justify-content-end">
							<Button
								type="submit"
								variant="primary"
								disabled={isSubmitting || hasCreated}
							>
								{isSubmitting && (
									<Spinner
										animation="border"
										size="sm"
										as="span"
										role="status"
										aria-hidden="true"
										className="mr-1 mb-1"
									/>
								)}
								Salvar
							</Button>
						</Card.Footer>
					</Form>
				</Card>
			</div>
		</div>
	);
};
