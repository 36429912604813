import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { SingleCheckout } from './pages/Checkout/Single';
import { RecurringCheckout } from './pages/Checkout/Recurring';
import { CreateCreditCard } from './pages/CreateCreditCard';
import { Chat } from './pages/Chat';
import { Unavailable } from './pages/Checkout/Unavailable';
import { UpdateSubscriptionCard } from './pages/UpdateSubscriptionCard';

export const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/unavailable" element={<Unavailable />} />
				<Route path="/p/:slug" element={<SingleCheckout />} />
				<Route path="/:platform/p/:slug" element={<SingleCheckout />} />
				<Route
					path="/p/recurring/:reference"
					element={<RecurringCheckout />}
				/>
				<Route
					path="/:platform/p/recurring/:reference"
					element={<RecurringCheckout />}
				/>
				<Route
					path="/credit-cards/create"
					element={<CreateCreditCard />}
				/>
				<Route
					path="/cp/subscriptions/:subscriptionCode/card"
					element={<UpdateSubscriptionCard />}
				/>
				<Route path="/chat" element={<Chat />} />
			</Routes>
		</BrowserRouter>
	);
};
