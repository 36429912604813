import axios, { AxiosInstance } from 'axios';

export class Api {
	private curseducaClasClient: AxiosInstance | null = null;

	private curseducaPayClient: AxiosInstance | null = null;

	private curseducaIntegrationsClient: AxiosInstance | null = null;

	private apiKey = '';

	constructor(apiKey = '') {
		this.apiKey = apiKey;
	}

	async isApiKeyDefined(): Promise<boolean> {
		return !!(await this.getApiKey());
	}

	async getCurseducaClasClient(): Promise<AxiosInstance> {
		if (this.curseducaClasClient) return this.curseducaClasClient;

		this.curseducaClasClient = axios.create({
			baseURL: process.env.REACT_APP_CURSEDUCA_CLAS_DOMAIN,
			headers: {
				api_key: await this.getApiKey(),
				Authorization: this.getAccessToken()
			}
		});

		return this.curseducaClasClient;
	}

	async getCurseducaPayClient(input?: {
		apiKey: string;
		accessToken: string;
	}): Promise<AxiosInstance> {
		if (this.curseducaPayClient && !input) {
			return this.curseducaPayClient;
		}

		this.curseducaPayClient = axios.create({
			baseURL: process.env.REACT_APP_CURSEDUCA_PAY_DOMAIN,
			headers: {
				api_key: input?.apiKey || (await this.getApiKey()),
				Authorization: input?.accessToken || this.getAccessToken()
			}
		});

		return this.curseducaPayClient;
	}

	async getCurseducaIntegrationsClient(): Promise<AxiosInstance> {
		if (this.curseducaIntegrationsClient)
			return this.curseducaIntegrationsClient;

		this.curseducaIntegrationsClient = axios.create({
			baseURL: process.env.REACT_APP_CURSEDUCA_INTEGRATIONS_DOMAIN,
			headers: {
				api_key: await this.getApiKey(),
				Authorization: this.getAccessToken()
			}
		});

		return this.curseducaIntegrationsClient;
	}

	private async getApiKey(): Promise<string> {
		try {
			if (this.apiKey) return this.apiKey;

			const queryParams = new URLSearchParams(window.location.search);
			const searchApiKey = queryParams.get('api_key');

			if (searchApiKey) return searchApiKey;

			if (
				location.hostname === process.env.REACT_APP_UNIFIED_CHECKOUT_URL
			)
				return '';

			const response = await axios.get<{ key: string }>('/z/api/key');

			if (response.data.key) this.apiKey = response.data.key;

			return this.apiKey;
		} catch {
			return '';
		}
	}

	private getAccessToken(): string {
		const queryParams = new URLSearchParams(window.location.search);

		const accessToken =
			queryParams.get('access_token') ||
			localStorage.getItem('access_token');
		return accessToken ? `Bearer ${accessToken}` : '';
	}
}
