import { Api } from '.';

class ApiFactory {
	private enabled: Api;
	private default: Api;
	private custom: Api | null = null;

	constructor() {
		const api = new Api();

		this.default = api;
		this.enabled = api;
	}

	public isDefaultApiKeyDefined = async (): Promise<boolean> => {
		return !!(await this.default.isApiKeyDefined());
	};

	public enableCustom = (apiKey: string): void => {
		const api = new Api(apiKey);
		this.custom = api;
		this.enabled = this.custom;
	};

	public enableDefault = (): void => {
		this.enabled = this.default;
	};

	public getInstance = (): Api => {
		return this.enabled;
	};
}

export const apiFactory = new ApiFactory();
