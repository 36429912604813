import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditCardsApi } from '../../api/creditCards';
import { useCep } from '../../utils/useCep';
import { CreateCreditCardInput } from './types/CreateCreditCardInput';
import { toast } from '../../utils/toast';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { apiFactory } from '../../api/ApiFactory';

type Subscription = {
	id: number;
	billing: {
		grossPrice: number;
	};
	plan: {
		name: string;
	};
};

export const useUpdateSubscriptionCard = () => {
	const { customization, finance } = useContext(ApplicationContext);

	const queryParams = new URLSearchParams(location.search);
	const { subscriptionCode } = useParams<{ subscriptionCode: string }>();
	const [apiKey, setApiKey] = useState<string | null>(null);
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const [hasCreated, setHasCreated] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [subscription, setSubscription] = useState<Subscription>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const akParam = queryParams.get('api_key');
		const atParam = queryParams.get('at');
		if (akParam && atParam) {
			setApiKey(akParam);
			setAccessToken(atParam);
		}
	}, []);

	const tokenizeCreditCardApi = useMutation(creditCardsApi.tokenize);
	const methods = useForm<CreateCreditCardInput>();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		control,
		formState: { errors }
	} = methods;

	useEffect(() => {
		if (!apiKey || !accessToken || !subscriptionCode) {
			return;
		}

		const fethSubscription = async () => {
			try {
				const CPay = await apiFactory
					.getInstance()
					.getCurseducaPayClient({
						apiKey: apiKey || '',
						accessToken: accessToken || ''
					});

				const { data } = await CPay.get<Subscription>(
					`/subscriptions/by?code=${subscriptionCode}`
				);
				setSubscription(data);
				setIsLoading(false);
			} catch (err) {
				toast.error('Erro ao buscar assinatura');
				console.log(err);
			}
		};

		fethSubscription();
	}, [apiKey, accessToken, subscriptionCode]);

	const onSubmit = async (creditCard: CreateCreditCardInput) => {
		setIsSubmitting(true);

		try {
			if (!subscription) {
				toast.error('Assinatura não encontrada');
				return;
			}

			creditCard.cpfCnpj = creditCard.cpfCnpj.replace(/\D/g, '');
			creditCard.address.postalcode =
				creditCard.address.postalcode.replace(/\D/g, '');
			creditCard.number = creditCard.number.replaceAll(' ', '');
			const [expiryMonth, expiryYear] = creditCard.expiry.split('/');

			const { card, id: token } = await tokenizeCreditCardApi.mutateAsync(
				{
					hasAntifraud: finance?.isAntifraudEnabled ?? true,
					card: {
						cvv: creditCard.cvv,
						holder_name: creditCard.holderName,
						number: creditCard.number,
						exp_month: Number(expiryMonth),
						exp_year: Number(expiryYear)
					},
					type: 'card'
				}
			);

			const CPay = await apiFactory.getInstance().getCurseducaPayClient({
				apiKey: apiKey || '',
				accessToken: accessToken || ''
			});

			await CPay.patch(`/v2/subscriptions/${subscription?.id}/card`, {
				creditCard: {
					token,
					holderName: creditCard.holderName,
					number: card.last_four_digits,
					expiryMonth: expiryMonth,
					expiryYear: expiryYear,
					cpfCnpj: creditCard.cpfCnpj,
					flag: card.brand,
					address: {
						...creditCard.address,
						number: String(creditCard.address.number)
					}
				}
			});

			setHasCreated(true);
			toast.success('Cartão criado com sucesso!');
			reset();
		} catch (e) {
			if (axios.isAxiosError(e)) {
				if (e.response?.data.errors) {
					const index = Object.keys(e.response?.data.errors).find(
						Boolean
					);

					return index
						? toast.error(e.response.data.errors[index])
						: toast.error(
								'Houve um erro desconhecido ao tokenizar cartão.'
						  );
				}

				return toast.error(
					e.response?.data.message ||
						'Houve um erro desconhecido ao tokenizar cartão.'
				);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleFetchCep = async (cep: string) => {
		const { bairro, logradouro, localidade, uf } = await useCep.fetch(cep);

		setValue('address.province', bairro);
		setValue('address.city', localidade);
		setValue('address.state', uf);
		setValue('address.address', logradouro);
	};

	return {
		isLoading,
		handleSubmit,
		register,
		control,
		errors,
		isSubmitting,
		hasCreated,
		handleFetchCep,
		onSubmit,
		customization,
		subscription
	};
};
