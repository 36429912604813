import { apiFactory } from '../ApiFactory';
import { CreateSubscriptionDto } from './types/create-subscription.dto';
import { OpenSubscriptionDto } from './types/open-subscription.dto';
import { ISubscription } from './types/subscription.type';

const create = async (data: CreateSubscriptionDto) => {
	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).post('/subscriptions', data);

	return response.data;
};

const open = async (data: OpenSubscriptionDto): Promise<ISubscription> => {
	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).post<ISubscription>('/subscriptions/open', data);

	return response.data;
};

export const subscriptionsApi = {
	create: {
		key: 'CREATE_SUBSCRIPTIONS',
		fetch: create
	},
	open
};
