import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';

import TagManager from 'react-gtm-module';

export const useGTM = () => {
	const [id, setId] = useState<string>();

	const { customization } = useContext(ApplicationContext);

	useEffect(() => {
		if (id) return;

		if (!customization?.url) return;

		axios
			.get<{ id: string }>(`${customization?.url || ''}/integrations/gtm`)
			.then((response) => {
				if (!response.data?.id) return;

				if (response.data.id.startsWith('UA-')) return;
				setId(response.data.id);
			})
			.catch(() => {
				throw new Error('Error while fetch GTM.');
			});
	}, [customization]);

	useEffect(() => {
		if (!id) return;
		TagManager.initialize({
			gtmId: id
		});
	}, [id]);
};
