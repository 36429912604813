import { createContext } from 'react';

type Customization = {
	primaryColor: string;
	secondaryColor: string;
	theme: {
		id: number;
		title: 'LIGHT' | 'DARK' | 'CUSTOM';
	};
	logo: string;
	url: string;
};

type Finance = {
	isCurseducaPayConfigured: boolean;
	isAnticipationEnabled: boolean;
	isAntifraudEnabled: boolean;
	isAddressRequired: boolean;
	isBoletoNPixInstallmentEnabled: false;
	shouldDisplayTerms: false;
};

export type IApplicationContext = {
	name?: string;

	customization?: Customization;

	finance?: Finance;

	integrations?: {
		whatsappUrl: string;
	};
};

export const ApplicationContext = createContext<IApplicationContext>({});
