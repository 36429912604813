import { useContext, useEffect, useState } from 'react';
import { IPlan } from '../../../../api/plans/interfaces/planInterface';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { PlanContext } from '../Context/PlanContext';
import { useInstallmentTax } from '../hooks/useInstallmentTax';

type PlanButtonProps = { plan: IPlan };

import './styles.scss';
import { getColorBrightness } from '../../../../utils/get-color-brightness';

export const PlanButton = ({ plan }: PlanButtonProps) => {
	const [hasTaxes, setHasTaxes] = useState<boolean>(true);

	const { finance } = useContext(ApplicationContext);
	const { plan: currentPlan, setPlan, plans } = useContext(PlanContext);
	const { customization } = useContext(ApplicationContext);
	const isDark = customization?.theme?.title === 'DARK';

	const cycleLabel = {
		1: 'por semana',
		2: 'por quinzena',
		3: 'por mês',
		4: 'por bimestre',
		5: 'por trimestre',
		6: 'por semestre',
		7: 'por ano'
	};

	const alternativeCycleLabel = {
		1: 'semanalmente',
		2: 'quinzenalmente',
		3: 'mensalmente',
		4: 'bimestralmente',
		5: 'trimestralmente',
		6: 'semestralmente',
		7: 'anualmente'
	};

	useEffect(() => {
		const pricingSchema = plan?.pricingSchema;

		const hasTaxes =
			(finance?.isAnticipationEnabled ?? true) &&
			pricingSchema?.anticipationResponsible === 'CUSTOMER';

		setHasTaxes(hasTaxes);
	}, [plan]);

	const getAlternativeCycleLabel = (cycleId: number) => {
		const cycle = cycleId as 1 | 2 | 3 | 4 | 5 | 6 | 7;
		return alternativeCycleLabel[cycle];
	};

	const getCycleLabel = (cycleId: number) => {
		const cycle = cycleId as 1 | 2 | 3 | 4 | 5 | 6 | 7;
		return cycleLabel[cycle];
	};

	const handleChangeCurrentPlan = (id: number) => {
		const plan = plans?.find((plan) => plan.id === id);

		if (!plan) return;

		setPlan(plan);
	};

	const { calculate, installments } = useInstallmentTax();

	useEffect(() => {
		calculate(
			plan.price,
			plan?.pricingSchema.maxInstallment || 1,
			hasTaxes
		);
	}, [plan, hasTaxes]);

	const priceWithDiscount = plan.price - (plan.discountOnPaymentInCash || 0);

	function getBorderColor() {
		if (!customization?.primaryColor) {
			return '';
		}

		const isPrimaryColorDark =
			getColorBrightness(customization.primaryColor) === 'dark';

		if (isDark && isPrimaryColorDark) {
			return '#fff';
		}

		return customization.primaryColor;
	}
	return (
		<button
			type="button"
			key={plan.id}
			onClick={() => handleChangeCurrentPlan(plan.id)}
			className={`${
				currentPlan?.id === plan.id ? 'active' : ''
			} bg-transparent rounded rounded-lg py-5 px-4 my-4 btn-block text-left ribbon ribbon-right plan-button`}
			style={{
				border:
					currentPlan?.id === plan.id
						? `3px solid ${getBorderColor()}`
						: isDark
						? '2px solid #343A40'
						: '2px solid #E9ECEF'
			}}
		>
			{plan.recommended && (
				<div
					className="ribbon-target rounded text-dark float-right position-relative"
					style={{
						top: '0px',
						right: '-18px',
						backgroundColor: customization?.primaryColor
					}}
				>
					Recomendado
				</div>
			)}
			<h5 className="h5 text-gray-800">{plan.name}</h5>

			<h4 className="h3 m-0 mt-4 text-gray-700 d-inline-block">
				{(plan?.pricingSchema.maxInstallment || 1) > 1
					? formatCurrency(
							installments[installments.length - 1]?.price || 0
					  )
					: formatCurrency(plan.price)}

				{(plan?.pricingSchema.maxInstallment || 1) > 1 && (
					<span className="d-inline-block ml-2 font-size-lg text-gray-600">
						em {plan?.pricingSchema.maxInstallment || 1}x{' '}
						{!finance?.isAnticipationEnabled && 'sem juros'}
					</span>
				)}
			</h4>
			{(plan?.pricingSchema.maxInstallment || 1) > 1 ? (
				<div className="font-weight-bold text-gray-600">
					<span className="d-block text-gray-600 mt-1">
						ou {formatCurrency(priceWithDiscount)} à vista /{' '}
						{getCycleLabel(plan.cycle)}
					</span>
				</div>
			) : (
				<span className="d-block text-dark-50 mt-1 font-weight-bold">
					à vista {getAlternativeCycleLabel(plan.cycle)}
				</span>
			)}
			{plan.trialDays ? (
				<span
					className="d-block mt-2 font-weight-bold"
					style={{ color: customization?.primaryColor }}
				>
					Teste gratuitamente por {plan.trialDays} dias
				</span>
			) : null}

			{plan.description && currentPlan?.id === plan.id ? (
				<p
					className="text-gray-600 mb-0 mt-4"
					dangerouslySetInnerHTML={{ __html: plan.description }}
				></p>
			) : (
				<></>
			)}
		</button>
	);
};
