import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';
import { IContent } from './interfaces/contentInterface';

type IGetBySlug = [string, { slug: string }];
type IGetOrderBump = [string, { uuid: string }];

const getBySlug: QueryFunction<IContent, IGetBySlug> = async ({ queryKey }) => {
	const [_, { slug }] = queryKey;

	const response = await (
		await apiFactory.getInstance().getCurseducaClasClient()
	).get<IContent>(`/contents/slug/${slug}`);

	return response.data;
};

const getOrderBump: QueryFunction<IContent | null, IGetOrderBump> = async ({
	queryKey
}) => {
	const [_, { uuid }] = queryKey;

	const client = await apiFactory.getInstance().getCurseducaClasClient();

	const { data } = await client.get<IContent>(
		`${process.env.REACT_APP_CURSEDUCA_CLAS_DOMAIN}/order-bump/${uuid}`
	);

	return data;
};

export const contentsApi = {
	getBySlug: {
		key: 'GET_CONTENT_BY_SLUG',
		fetch: getBySlug
	},
	getOrderBump: {
		key: 'GET_RDER_BUMP',
		fetch: getOrderBump
	}
};
