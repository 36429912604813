import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { ContentContext } from '../Context/ContentContext';
import { CouponContext } from '../Context/CouponContext';
import { OrderBumpContext } from '../Context/OrderBumpContext';
import { PlanContext } from '../Context/PlanContext';
import { useInstallmentTax } from '../hooks/useInstallmentTax';

interface IProps extends FormControlProps {
	customPrice?: number;
	maxInstallment?: number;
	setValue: (value: number) => void;
}

const InstallmentsInput = forwardRef(
	({ customPrice, maxInstallment, setValue, ...props }: IProps, ref) => {
		const [hasTaxes, setHasTaxes] = useState<boolean>(true);

		const { orderBump } = useContext(OrderBumpContext);
		const { finance } = useContext(ApplicationContext);

		const { plan } = useContext(PlanContext);
		const { currentPrice, content } = useContext(ContentContext);
		const { coupon } = useContext(CouponContext);

		const { calculate, installments } = useInstallmentTax();

		useEffect(() => {
			const pricingSchema = plan
				? plan?.pricingSchema
				: content?.pricing.pricingSchema;

			const isAnticipationEnabled =
				finance?.isAnticipationEnabled ?? true;
			const anticipationResponsible =
				pricingSchema?.anticipationResponsible ?? 'CUSTOMER';

			const hasTaxes =
				!content?.pricing.isSmartInstallmentEnabled &&
				isAnticipationEnabled &&
				anticipationResponsible === 'CUSTOMER';

			setHasTaxes(hasTaxes);
		}, [plan, content, finance]);

		useEffect(() => {
			if (!currentPrice && customPrice) return;

			const isSmartInstallment =
				content?.pricing.isSmartInstallmentEnabled;

			calculate(
				(customPrice || plan?.price || currentPrice || 0) +
					(orderBump?.pricing.price || 0) +
					(isSmartInstallment ? coupon?.flatDiscount || 0 : 0),
				maxInstallment || 12,
				hasTaxes,
				coupon?.flatDiscount || 0
			);
		}, [currentPrice, customPrice, plan, hasTaxes, orderBump, coupon]);

		useEffect(() => {
			setValue(installments.length);
		}, [installments]);

		return (
			<Form.Control as="select" {...props} ref={ref} size="lg">
				{installments.map(({ number, price, label }) => (
					<option
						key={number}
						value={number}
						selected={number === installments.length}
					>
						{label || `${number}x de ${formatCurrency(price)}`}
					</option>
				))}
			</Form.Control>
		);
	}
);

InstallmentsInput.displayName = 'InstallmentsInput';

export { InstallmentsInput };
