import TextField from '@mui/material/TextField';
import { useContext, useEffect, useRef, useState } from 'react';
import {
	Card,
	Row,
	Spinner,
	Col,
	Button,
	Form as BsForm
} from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { BillingType } from '../constants/billingType';
import { Inputs } from './inputs';
import axios from 'axios';
import { AddressForm } from '../AddressForm';
import { useMutation } from 'react-query';
import { useSubmit } from '../hooks/useSubmit';
import { ContentContext } from '../Context/ContentContext';
import { PaymentContext } from '../Context/PaymentContext';
import { PlanContext } from '../Context/PlanContext';
import { paymentsApi } from '../../../../api/payments';
import { membersApi } from '../../../../api/members';
import { PhoneInput } from '../../../../components/Inputs/PhoneInput';
import { CpfCnpjInput } from '../../../../components/Inputs/CpfCnpjInput';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { toast } from '../../../../utils/toast';
import { isValidDocument } from '../../../../utils/validators/document';
import { BillingTypeForm } from '../BillingTypeForm';
import { Checkbox } from '../../../../components/Inputs/Checkbox';

import { OrderBump } from '../OrderBump';
import { useSearchParams } from 'react-router-dom';
import { CouponContext } from '../Context/CouponContext';
import { InstallmentsInput } from '../InstallmentsInput';
import { SubscriptionContext } from '../Context/SubscriptionContext';
import { subscriptionsApi } from '../../../../api/subscriptions';
import { ShieldProtectedIcon } from '../../../../components/icons/ShieldProtected';
import { useGoogleAnalytics } from '../../../../hooks/Analytics';

export const Form = () => {
	const [shouldShowInstallmentInput, setShowInstallmentInput] =
		useState<boolean>(false);

	const [hasSignedTerms, setHasSignedTerms] = useState<boolean>(false);

	const [searchParams] = useSearchParams();

	const { plan } = useContext(PlanContext);
	const {
		content,
		currentPrice,
		setCurrentPrice,
		isLoading,
		billingType,
		setBillingType
	} = useContext(ContentContext);
	const { coupon } = useContext(CouponContext);
	const { setPayment } = useContext(PaymentContext);
	const { setSubscription } = useContext(SubscriptionContext);
	const { customization, finance } = useContext(ApplicationContext);

	const { handle } = useSubmit();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const createMemberApi = useMutation(membersApi.create);
	const openPaymentApi = useMutation(paymentsApi.open);
	const openSubscriptionApi = useMutation(subscriptionsApi.open);

	const methods = useForm<Inputs>({ defaultValues: { phone: '55' } });
	const isForeign = methods.watch('isForeign');

	const creditCard = methods.watch('creditCards')?.[0]?.creditCard;
	const userChangedCreditCard =
		typeof creditCard === 'object'
			? Object.values(creditCard)?.some(Boolean)
			: false;

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
		setValue
	} = methods;

	const [validated, setValidated] = useState<boolean>(false);

	const { beginCheckout, addPaymentInfo } = useGoogleAnalytics();

	useEffect(() => {
		if (!currentPrice || !content?.pricing.price) return;

		const discountedPrice =
			content?.pricing.price - (coupon?.flatDiscount || 0);

		let price = discountedPrice;

		switch (billingType) {
			case BillingType.CreditCard: {
				const creditCardDiscount =
					content?.pricing.flatCreditCardDiscount || 0;
				price = discountedPrice - creditCardDiscount;
				break;
			}
			case BillingType.Pix: {
				const pixDiscount = content?.pricing.flatPixDiscount || 0;
				price = discountedPrice - pixDiscount;
				break;
			}
			case BillingType.Boleto: {
				const ticketDiscount = content?.pricing.flatTicketDiscount || 0;
				price = discountedPrice - ticketDiscount;
				break;
			}
		}

		if (price < 0) price = 0;

		setCurrentPrice(price);
	}, [billingType, content]);

	useEffect(() => {
		if (
			!content?.pricing.isInstallmentEnabled &&
			!((plan?.pricingSchema.maxInstallment || 1) > 1)
		) {
			return setShowInstallmentInput(false);
		}

		const isSmartInstallmentEnabledForPix =
			finance?.isBoletoNPixInstallmentEnabled &&
			content?.pricing.isSmartInstallmentEnabled;

		if (
			billingType !== BillingType.CreditCard &&
			!isSmartInstallmentEnabledForPix
		) {
			return setShowInstallmentInput(false);
		}

		return setShowInstallmentInput(true);
	}, [finance, content, plan, billingType]);

	const alreadySendedBeginCheckoutEvent = useRef(false);
	const alreadySendedAddPaymentInfoEvent = useRef(false);

	useEffect(() => {
		if (!currentPrice) return;

		alreadySendedBeginCheckoutEvent.current = false;
		alreadySendedAddPaymentInfoEvent.current = false;
	}, [currentPrice]);

	useEffect(() => {
		sendAddPaymentInfoEventToGa4();
	}, [userChangedCreditCard]);

	const handleNameAndEmailBlur = async () => {
		sendBeginCheckoutEventToGa4();

		const { email, name, phone } = getValues();

		if (!email || !name || !phone) {
			return;
		}

		const emailValidator =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.match(emailValidator)) {
			toast.error('Email inválido');
			return;
		}

		if (phone.length !== 2) {
			const phoneValidator = /^\d{2} \d{2} \d{9}$/;

			if (!phone.match(phoneValidator)) {
				toast.error('telefone inválido');
				return;
			}
		}

		try {
			await handleMemberCreation();
		} catch {
			false;
		}

		if (plan) {
			handleSubscriptionOpening();
		} else {
			handlePaymentOpening();
		}
	};

	const sendBeginCheckoutEventToGa4 = () => {
		if (alreadySendedBeginCheckoutEvent.current) return;

		beginCheckout({
			value: currentPrice || 0
		});
		alreadySendedBeginCheckoutEvent.current = true;
	};

	const sendAddPaymentInfoEventToGa4 = () => {
		if (alreadySendedAddPaymentInfoEvent.current) return;
		addPaymentInfo({
			value: currentPrice || 0
		});
		alreadySendedBeginCheckoutEvent.current = true;
	};

	const handleMemberCreation = async () => {
		const { name, email, phone } = getValues();

		if (!email || !name || !phone || phone.length === 2) return;

		try {
			const { data } = await createMemberApi.mutateAsync({
				name,
				email,
				phone,
				metadata: { url: customization?.url }
			});

			localStorage.setItem('access_token', data.access_token);
		} catch (error) {
			try {
				const searchParams = new URLSearchParams({
					email
				});
				const { data } = await axios.get<{ access_token: string }>(
					`${
						customization?.url || ''
					}/user-access-token?${searchParams.toString()}`,
					{
						withCredentials: true
					}
				);
				if (data?.access_token) {
					localStorage.setItem('access_token', data.access_token);
				}
				// eslint-disable-next-line no-empty
			} catch {}
		}
	};

	const handlePaymentOpening = async () => {
		const { email } = getValues();

		if (!email || !content) return;

		const payment = await openPaymentApi.mutateAsync({
			email,
			contentId: content.id
		});
		setPayment(payment);
	};

	const handleSubscriptionOpening = async () => {
		const { email } = getValues();

		if (!email && !plan?.id) return;

		try {
			const subscription = await openSubscriptionApi.mutateAsync({
				email,
				recurringPlanId: plan?.id as number
			});
			setSubscription({ id: subscription.id });
		} catch (err) {
			false;
		}
	};

	const handleError = (message: string) => {
		toast.error(message);
		setIsSubmitting(false);
	};

	const onSubmit = async (data: Inputs) => {
		try {
			const queryParams = new URLSearchParams({
				access_token: localStorage.getItem('access_token') || ''
			});

			setIsSubmitting(true);
			setValidated(true);

			await handleNameAndEmailBlur();

			const payment = await handle({ billingType, ...data });

			const redirect = searchParams.get('redirect');
			if (redirect) return location.replace(redirect);

			if (plan) {
				return location.replace(
					`${customization?.url}/p/recurring/thanks/${
						plan.reference
					}?${queryParams.toString()}`
				);
			}

			return location.replace(
				`${customization?.url}/p/payment/${
					payment?.reference
				}?${queryParams.toString()}`
			);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				return handleError(err.response?.data.message);
			}

			if (err instanceof Error) {
				return handleError(err.message);
			}

			return handleError(
				'Um erro desconhecido ocorreu. Tente novamente mais tarde.'
			);
		}
	};

	return (
		<FormProvider {...methods}>
			<BsForm
				noValidate
				validated={validated}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Card.Body className="card-body">
					{isLoading ? (
						<Row className="justify-content-center mt-10">
							<Spinner
								animation="border"
								variant="light"
								style={{
									height: '5rem',
									width: '5rem',
									borderWidth: '.6rem'
								}}
							/>
						</Row>
					) : (
						<>
							<Card.Title className="card-title mt-5 mt-md-8">
								<h3 className="card-label h4 text-gray-800">
									Informações pessoais{' '}
								</h3>
							</Card.Title>
							<Row>
								<Col className="mb-5" lg={12}>
									<TextField
										label="Nome completo"
										className="w-100"
										required
										type="text"
										{...register('name', {
											required:
												'O campo de nome é obrigatório!',
											maxLength: {
												message:
													'O campo de nome deve conter até no máximo 64 caracteres!',
												value: 64
											}
										})}
										placeholder="Francisco Silva"
										error={!!errors.name}
										helperText={errors.name?.message}
										onBlur={handleNameAndEmailBlur}
									/>
								</Col>
								<Col className="mb-5 pr-md-1" lg={8}>
									<TextField
										label="Email"
										className="w-100 "
										required
										sx={{
											borderRadius: 89
										}}
										type="email"
										{...register('email', {
											required:
												'O campo de email é obrigatório.',
											maxLength: {
												message:
													'O campo de nome deve conter até no máximo 100 caracteres!',
												value: 100
											},
											pattern: {
												value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
												message: 'Email inválido'
											}
										})}
										placeholder="exemplo@dominio.com"
										error={!!errors.email}
										helperText={errors.email?.message}
										onBlur={handleNameAndEmailBlur}
									/>
								</Col>

								<Col className="mb-5 pl-md-1" lg={4}>
									<PhoneInput
										required
										className="w-100"
										type="text"
										{...register('phone', {
											required:
												'O campo de celular é obrigatório!',
											pattern: {
												value: /^[0-9]{2} [0-9]{2} 9[0-9]{8}$/,
												message: 'Celular inválido'
											}
										})}
										error={!!errors.phone}
										helperText={errors.phone?.message}
										onBlur={handleNameAndEmailBlur}
									/>
								</Col>
							</Row>

							<Row className="align-items-center">
								{finance?.isAntifraudEnabled === false && (
									<Col className="d-none mb-5" xs={5}>
										<Checkbox
											label="Sou estrangeiro"
											{...register('isForeign')}
										/>
									</Col>
								)}
								<Col className="mb-5" xs={12}>
									<CpfCnpjInput
										{...register('cpfCnpj', {
											required:
												'O campo de documento é obrigatório!',
											validate: (value) =>
												isForeign ||
												isValidDocument(value)
										})}
										onBlur={handleNameAndEmailBlur}
										error={!!errors.cpfCnpj}
										helperText={
											errors.cpfCnpj?.type === 'validate'
												? 'Documento inválido'
												: errors.cpfCnpj?.message
										}
										isForeign={isForeign}
										required
									/>
								</Col>
							</Row>

							{finance?.isAddressRequired && !isForeign && (
								<AddressForm />
							)}

							<div>
								{(currentPrice || plan?.price) && (
									<>
										<BillingTypeForm
											setBillingType={setBillingType}
										/>
										<Row>
											<Col
												md={12}
												className={`${
													shouldShowInstallmentInput
														? ''
														: 'd-none'
												}`}
											>
												<BsForm.Group>
													<InstallmentsInput
														setValue={(
															value: number
														) =>
															setValue(
																`installmentCount`,
																value
															)
														}
														{...register(
															`installmentCount`,
															{
																value: 1,
																valueAsNumber:
																	true
															}
														)}
														maxInstallment={
															plan
																? plan
																		.pricingSchema
																		.maxInstallment
																: content
																		?.pricing
																		.maxInstallment
														}
														isInvalid={
															!!errors.installmentCount
														}
													/>
													{content?.pricing
														.isSmartInstallmentEnabled ? (
														<span className="d-block mt-2 font-weight-bold text-muted">
															Esta compra será
															feita com
															parcelamento
															inteligente, em que
															o valor total do
															produto será
															dividido em
															mensalidades sem
															bloquear o limite no
															seu cartão de
															crédito.
														</span>
													) : (
														''
													)}
												</BsForm.Group>
											</Col>
										</Row>
									</>
								)}

								{finance?.shouldDisplayTerms && (
									<>
										<label className="checkbox">
											<input
												type="checkbox"
												onChange={(e) =>
													setHasSignedTerms(
														e.target.checked
													)
												}
											/>
											<span
												style={{
													minWidth: '18px'
												}}
												className="mr-3"
											></span>
											<p className="text-dark-75 mb-0 font-size-lg">
												Eu li e concordo com os{' '}
												<a
													target="_blank"
													href={`${customization?.url}/terms`}
													rel="noreferrer"
													className="font-weighti-bolder"
												>
													termos de uso
												</a>{' '}
												e{' '}
												<a
													target="_blank"
													href={`${customization?.url}/privacy`}
													rel="noreferrer"
													className="font-weight-bolder"
												>
													políticas de privacidade
												</a>
											</p>
										</label>
									</>
								)}

								<OrderBump />

								<Button
									size="lg"
									type="submit"
									className="d-block mx-auto mt-5 mt-md-8 border-0 w-100 max-w-450px font-weight-bold rounded-lg py-5 bg-hover-scaleup h5"
									style={{
										backgroundColor:
											customization?.primaryColor,
										color:
											customization?.secondaryColor ||
											'#FFF'
									}}
									disabled={
										isSubmitting ||
										(!hasSignedTerms &&
											finance?.shouldDisplayTerms)
									}
								>
									{isSubmitting && (
										<Spinner
											animation="border"
											size="sm"
											as="span"
											role="status"
											aria-hidden="true"
											className="mr-1 mb-1"
										/>
									)}
									{currentPrice || plan?.price
										? 'Finalizar compra'
										: 'Acessar'}
								</Button>
							</div>
						</>
					)}
				</Card.Body>
				<div className="d-flex align-items-center justify-content-center justify-self-end mb-5 text-center text-success">
					<span className="svg-icon svg-icon-lg svg-icon-success mr-2">
						<ShieldProtectedIcon />
					</span>
					<span className="font-size-lg">Ambiente seguro</span>
				</div>
				<div className="d-block d-md-none justify-self-end mb-5 text-center text-muted">
					Powered by{' '}
					<span className="font-weight-bolder">Curseduca</span>
				</div>
			</BsForm>
		</FormProvider>
	);
};
