import toastr from 'toastr';

const Toastr = () => {
	toastr.options.timeOut = 10000;
	toastr.options.positionClass = 'toast-bottom-right';
	toastr.options.closeButton = true;
	toastr.options.preventDuplicates = true;

	return {
		info: (message: string) => toastr.info(message),
		success: (message: string) => toastr.success(message),
		warning: (message: string) => toastr.warning(message),
		error: (message: string) => toastr.error(message)
	};
};

export const toast = Toastr();
