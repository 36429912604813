import { useEffect } from 'react';

export const useBlockedPlatform = () => {
	const href = window.location.pathname;

	const blockedPlatforms = ['academiadopadraoalimentar'];

	useEffect(() => {
		if (blockedPlatforms.some((p) => href.includes(p))) {
			location.href = 'https://curseduca.com';
		}
	}, []);
};
