import { useContext, useEffect, useState } from 'react';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { ContentContext } from '../Context/ContentContext';
import { PlanContext } from '../Context/PlanContext';

type Installment = { number: number; price: number; label?: string };

export const useInstallmentTax = () => {
	const { content } = useContext(ContentContext);
	const { plan } = useContext(PlanContext);

	const [allInstallments, setAllInstallments] = useState<Installment[]>([]);
	const [installments, setInstallments] = useState<Installment[]>([]);
	const [discount, setDiscount] = useState(0);

	const calculate = (
		price: number,
		maxInstallment: number,
		hasTaxes: boolean,
		discountToBeSet = 0
	) => {
		let discountToBeApplied = 0;

		if (content && content?.pricing.isSmartInstallmentEnabled) {
			discountToBeApplied = discountToBeSet;
		}

		if (
			plan?.discountOnPaymentInCash &&
			plan?.discountOnPaymentInCash > 0
		) {
			discountToBeApplied = plan.discountOnPaymentInCash;
		}

		if (content && content?.pricing.isSmartInstallmentEnabled) {
			setDiscount(discountToBeSet);
		}

		const newInstallments = [];

		for (let i = 1; i <= (maxInstallment || 12); i++) {
			const installmentValue = (price || 0) / i;
			let taxes = 0;

			if (hasTaxes) {
				for (let j = 1; j <= i; j++) {
					taxes += i === 1 ? 0 : installmentValue * j * 0.0299;
				}
			}

			const installment =
				installmentValue + Math.ceil(taxes * 100) / 100 / i;

			newInstallments.push({
				number: i,
				price:
					i === 1
						? Number((installment - discountToBeApplied).toFixed(2))
						: installment
			});
		}

		setAllInstallments(newInstallments);
	};

	useEffect(() => {
		const installmentsSchema =
			content?.pricing.pricingSchema?.installmentsSchema;

		if (!installmentsSchema || installmentsSchema?.length === 0) {
			setInstallments(allInstallments);
			return;
		}

		const result = allInstallments.filter((installment) => {
			return installmentsSchema.find(
				(schema) => schema.count === installment.number
			)?.isActive;
		});

		setInstallments(
			result.map((row) => {
				const firstInstallment = installmentsSchema.find(
					(schema) => schema.count === 1
				);
				const secondInstallment = installmentsSchema.find(
					(schema) => schema.count > 1
				);

				return {
					number: row.number,
					price: row.price,
					label: `1x de ${formatCurrency(
						(firstInstallment?.price || 0) - discount
					)} + ${row.number - 1}x de ${formatCurrency(
						secondInstallment?.price || 0
					)}`
				};
			})
		);
	}, [
		allInstallments,
		content?.pricing.pricingSchema?.installmentsSchema,
		discount
	]);

	return {
		installments,
		calculate
	};
};
