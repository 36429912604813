import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import { useContext, useEffect, useState } from 'react';
import { FbPixelContext } from '../../contexts/FbPixelContext';
import { FbPixelEvents } from './events';
import { ApplicationContext } from '../../contexts/ApplicationContext';

export { FbPixelEvents };

type FbPixelActions = {
	pageView: () => void;
	track: (title: string, data?: unknown) => void;
};

export const useFbPixel = () => {
	const [actions, setActions] = useState<FbPixelActions>({
		pageView: () => false,
		track: () => false
	});

	const { fbPixel, setFbPixel } = useContext(FbPixelContext);
	const { customization } = useContext(ApplicationContext);

	useEffect(() => {
		if (fbPixel) ReactPixel.init(fbPixel);
	}, [fbPixel]);

	useEffect(() => {
		if (fbPixel) return;
		if (!customization?.url) return;

		axios
			.get<{ pixel: string }>(
				`${customization?.url || ''}/integrations/fbpixel`
			)
			.then((response) => {
				if (!response.data?.pixel) return;

				setFbPixel(response.data.pixel);
				setActions({
					pageView: ReactPixel.pageView,
					track: ReactPixel.track
				});
			})
			.catch(() => {
				throw new Error('Error while fetch FB Pixel.');
			});
	}, [customization]);

	return actions;
};
