import axios from 'axios';

type FetchResult = {
	cep: string;
	logradouro: string;
	complemento: string;
	bairro: string;
	localidade: string;
	uf: string;
	ibge: string;
	gia: string;
	ddd: string;
	siafi: string;
};
4;
export const useCep = {
	fetch: async (cep: string): Promise<FetchResult> => {
		const result = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

		return result.data;
	}
};
