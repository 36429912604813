import { createContext } from 'react';
import { IContent } from '../../../../api/contents/interfaces/contentInterface';

interface IOrderBumpContext {
	orderBump?: IContent | null;
	setOrderBump: (content: IContent | null) => void;
}

export const OrderBumpContext = createContext<IOrderBumpContext>({
	setOrderBump: (content: IContent | null) => false
});
