import '../../../_metronic/_assets/plugins/flaticon/flaticon.css';
import '../../../_metronic/_assets/plugins/flaticon2/flaticon.css';
import { contentsApi } from '../../../api/contents';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { Row } from 'react-bootstrap';

import { useFbPixel, FbPixelEvents } from '../../../hooks/FbPixel';
import { useQuery } from 'react-query';
import { checkoutsApi } from '../../../api/checkouts';
import { WhatsAppWidget } from '../../../components/widgets/WhatsAppWidget';
import { IPayment } from '../../../api/payments/types/payment.type';
import axios from 'axios';
import { apiFactory } from '../../../api/ApiFactory';
import { ContentContext } from '../components/Context/ContentContext';
import { Sidebar } from '../components/Sidebar';
import { ICoupon } from '../../../api/coupons/interfaces/couponInterface';
import { PaymentContext } from '../components/Context/PaymentContext';
import { Counter } from '../components/Counter';
import { CouponContext } from '../components/Context/CouponContext';
import { Form } from '../components/Form';
import { IContent } from '../../../api/contents/interfaces/contentInterface';
import { OrderBumpContext } from '../components/Context/OrderBumpContext';
import { useGTM } from '../../../hooks/GTM';
import { useGoogleAnalytics } from '../../../hooks/Analytics';
import { BillingType } from '../components/constants/billingType';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { useBlockedPlatform } from '../../../hooks/use-blocked-platform';

interface IParams extends Record<string, string> {
	slug: string;
	platform: string;
}

const { getBySlug } = contentsApi;
const { findByContent } = checkoutsApi;

export const SingleCheckout = () => {
	const navigate = useNavigate();

	const [billingType, setBillingType] = useState(BillingType.CreditCard);

	const [orderBump, setOrderBump] = useState<IContent | null>();

	const { slug, platform } = useParams<IParams>() as IParams;
	const { track } = useFbPixel();
	const { pageView } = useGoogleAnalytics();
	useGTM();
	useBlockedPlatform();

	const { data: content, isLoading } = useQuery(
		[getBySlug.key, { slug }],
		getBySlug.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	const { data: checkout } = useQuery(
		[findByContent.key, { id: content?.id }],
		findByContent.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	const { finance } = useContext(ApplicationContext);

	const [currentPrice, setCurrentPrice] = useState<number>(0);
	const [quantity, setQuantity] = useState<number>(1);
	const [coupon, setCoupon] = useState<ICoupon | null>();
	const [payment, setPayment] = useState<IPayment | null>(null);

	useEffect(() => {
		const href = window.location.pathname;

		const forbiddenPaths = [
			'/forumiep/p/forum-iep-estudantes-2-lote',
			'/forumiep/p/forum-iep-profissionais-2-lote'
		];

		if (forbiddenPaths.includes(href)) {
			if (href.startsWith('/forumiep')) {
				location.href = 'https://forumiep.curseduca.pro/l/';
			}
		}
	}, []);

	useEffect(() => {
		if (!finance) return;
		if (finance.isCurseducaPayConfigured === true) return;

		if (!content) return;
		if (content.pricing.price === 0) return;

		navigate('/unavailable');
	}, [finance, content]);

	useEffect(() => {
		pageView();
	}, [pageView]);

	useEffect(() => {
		if (!track || !content?.pricing.price) return;

		track(FbPixelEvents.InitiateCheckout, {
			value: content?.pricing.price,
			currency: 'BRL'
		});
	}, [track, content]);

	useEffect(() => {
		setCurrentPrice(content?.pricing.price || 0);
	}, [content]);

	useEffect(() => {
		apiFactory.isDefaultApiKeyDefined().then((response) => {
			if (response) return;
			axios
				.get<{ key: string }>(
					`${process.env.REACT_APP_CURSEDUCA_APPLICATION_DOMAIN}/platforms/slug/${platform}`
				)
				.then((response) => {
					apiFactory.enableCustom(response.data.key);
				});
		});

		return () => apiFactory.enableDefault();
	}, []);

	useEffect(() => {
		if (!window?.location) {
			return;
		}

		const path = window.location.pathname;
		const toRedirect = [
			'/forumiep/p/frum-iep-2024-estudantes-2o-lote-1713534226249',
			'/forumiep/p/frum-iep-2024-estudantes-1o-lote-1713534216722',
			'/forumiep/p/frum-iep-2024-profissionais-2o-lote-1713534204425',
			'/forumiep/p/frum-iep-2024-profissionais-1o-lote-1713534188714'
		];

		if (!toRedirect.includes(path)) {
			return;
		}

		location.href = 'https://forumiep.curseduca.pro/l/';
	}, []);

	return (
		<ContentContext.Provider
			value={{
				content,
				currentPrice,
				setCurrentPrice,
				quantity,
				setQuantity,
				isLoading,
				checkout,
				billingType,
				setBillingType
			}}
		>
			<PaymentContext.Provider value={{ payment, setPayment }}>
				<OrderBumpContext.Provider
					value={{
						setOrderBump,
						orderBump
					}}
				>
					<CouponContext.Provider
						value={{
							coupon,
							setCoupon: (coupon) => setCoupon(coupon)
						}}
					>
						<div
							style={{
								backgroundImage: `url(${checkout?.backgroundImage})`,
								backgroundSize: 'cover'
							}}
							className="d-flex flex-column align-items-center"
						>
							<Counter />
							<div className="content">
								<div className="container">
									{checkout?.headerImage && (
										<Row>
											<img
												className="w-100 h-100"
												src={checkout.headerImage}
											/>
										</Row>
									)}
									<div className="checkout-grid">
										<Card className="rounded-xl p-md-10 form-grid-item">
											<Form />
										</Card>
										<Sidebar />
									</div>
								</div>
							</div>
						</div>
						<WhatsAppWidget />
					</CouponContext.Provider>
				</OrderBumpContext.Provider>
			</PaymentContext.Provider>
		</ContentContext.Provider>
	);
};
