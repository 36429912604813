import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { applicationsApi } from './api/applications';
import { customizationsApi } from './api/customizations';
import {
	ApplicationContext,
	IApplicationContext
} from './contexts/ApplicationContext';
import { FbPixelContext } from './contexts/FbPixelContext';
import { Router } from './routes';
import { MixPanel } from './shared/MixPanel';

export const App = () => {
	const [fbPixel, setFbPixel] = useState<string>();
	const [application, setApplication] = useState<IApplicationContext>({});

	const { data: customizationsData } = useQuery(
		[customizationsApi.find.key],
		customizationsApi.find.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	const { data: financeData } = useQuery(
		[applicationsApi.getFinanceParams.key],
		applicationsApi.getFinanceParams.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	useEffect(() => {
		if (!financeData) return;

		const {
			isAddressRequired,
			isAnticipationEnabled,
			isAntifraudEnabled,
			isBoletoNPixInstallmentEnabled,
			shouldDisplayTerms,
			isCurseducaPayConfigured
		} = financeData;

		setApplication((prev) => ({
			...prev,
			finance: {
				isCurseducaPayConfigured: isCurseducaPayConfigured ?? true,
				isAddressRequired,
				isAnticipationEnabled,
				isAntifraudEnabled,
				isBoletoNPixInstallmentEnabled,
				shouldDisplayTerms
			}
		}));
	}, [financeData]);

	useEffect(() => {
		if (!customizationsData) return;

		const { customization } = customizationsData;

		setApplication(() => ({
			...application,
			customization: {
				...customization,
				primaryColor: `#${customization.primaryColor}`,
				secondaryColor: `#${customization.secondaryColor}`
			}
		}));
	}, [customizationsData]);

	useEffect(() => {
		if (application.name) document.title = application.name;
	}, [application]);

	if (
		customizationsData?.customization?.theme?.title === 'DARK' &&
		window?.location?.pathname !== '/credit-cards/create'
	) {
		document.body.classList.add('dark-mode');
	}

	return (
		<ApplicationContext.Provider value={application}>
			<FbPixelContext.Provider value={{ fbPixel, setFbPixel }}>
				<Router />
				<MixPanel />
			</FbPixelContext.Provider>
		</ApplicationContext.Provider>
	);
};
