import mixpanel from 'mixpanel-browser';
import { useEffect, useRef } from 'react';

export const MixPanel = () => {
	const hasInitialized = useRef(false);

	useEffect(() => {
		if (hasInitialized.current) {
			return;
		}

		mixpanel.init('d8f2f6d06641f8b825dc466d7e81f2c6', {
			debug: true,
			track_pageview: true,
			persistence: 'localStorage'
		});
		hasInitialized.current = true;
	}, []);

	return <></>;
};
