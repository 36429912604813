import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, ChangeEventHandler } from 'react';

import InputMask from 'react-input-mask';
import { ITextInputProps } from '../TextInputPropsInterface';

const CreditCardNumberInput = forwardRef(
	({ onChange, onBlur, onFocus, ...props }: ITextInputProps, ref) => {
		return (
			<InputMask
				mask="9999 9999 9999 9999"
				maskPlaceholder={null}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
			>
				<TextField
					type="text"
					{...props}
					inputRef={ref}
					label="Número do cartão de crédito*"
					placeholder="1234 1234 1234 1234"
				/>
			</InputMask>
		);
	}
);
CreditCardNumberInput.displayName = 'CreditCardNumberInput';

type IsForeign = {
	isForeign: boolean;
};

const CreditCardExpirationInput = forwardRef(
	(
		{ onChange, onBlur, isForeign, ...props }: ITextInputProps & IsForeign,
		ref
	) => {
		return (
			<InputMask
				mask="99/99"
				maskPlaceholder={null}
				onBlur={onBlur}
				onChange={onChange}
			>
				<TextField
					type="text"
					{...props}
					inputRef={ref}
					label="Expiração*"
					placeholder={isForeign ? 'YY/MM' : 'MM/AA'}
				/>
			</InputMask>
		);
	}
);

CreditCardExpirationInput.displayName = 'CreditCardExpirationInput';

const HolderNameInput = forwardRef(
	({ onChange, ...props }: TextFieldProps, ref) => {
		const handleOnChange: ChangeEventHandler<
			HTMLInputElement | HTMLTextAreaElement
		> = (e) => {
			e.target.value = e.target.value
				.replace(/[^a-z\s]+/gi, '')
				.toUpperCase();
		};

		return (
			<TextField
				inputRef={ref}
				type="text"
				placeholder="Digite o nome como impresso no cartão"
				label="Nome impresso no cartão*"
				onChange={(e) => {
					handleOnChange(e);
					if (onChange) return onChange(e);
				}}
				{...props}
			/>
		);
	}
);

HolderNameInput.displayName = 'HolderNameInput';

export {};

export { CreditCardNumberInput, CreditCardExpirationInput, HolderNameInput };
