import React, { useContext, useEffect, useState } from 'react';

import { Card, Row, Spinner } from 'react-bootstrap';
import { Coupon } from '../Coupon';

import { PlanContext } from '../Context/PlanContext';
import { ContentContext } from '../Context/ContentContext';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { CouponContext } from '../Context/CouponContext';
import { PlanButton } from '../PlanButton';
import { OrderBumpContext } from '../Context/OrderBumpContext';
import { isUrl } from '../../../../utils/validators/url';
import { BillingType } from '../constants/billingType';

export const Sidebar = () => {
	const [label, setLabel] = useState<React.ReactNode>(null);

	const { customization } = useContext(ApplicationContext);

	const [currentMinusBg, setCurrentMinusBg] = useState<string>('unset');
	const [currentPlusBg, setCurrentPlusBg] = useState<string>('unset');

	const [currentMinusColor, setCurrentMinusColor] = useState<string>(
		customization?.primaryColor || '#fff'
	);
	const [currentPlusColor, setCurrentPlusColor] = useState<string>(
		customization?.primaryColor || '#fff'
	);

	useEffect(() => {
		setCurrentPlusColor(customization?.primaryColor || '#fff');
		setCurrentMinusColor(customization?.primaryColor || '#fff');
	}, [customization]);

	const { orderBump } = useContext(OrderBumpContext);

	const { plan, plans } = useContext(PlanContext);

	const {
		content,
		isLoading,
		currentPrice,
		setCurrentPrice,
		quantity,
		setQuantity,
		billingType
	} = useContext(ContentContext);
	const { coupon } = useContext(CouponContext);

	const image = content?.images.find((image) => {
		return image.type === 1;
	});

	useEffect(() => {
		const installments = content?.pricing.pricingSchema?.installmentsSchema;
		if (!installments || installments.length === 0) return;

		const firstInstallment = installments.find(
			(schema) => schema.count === 1
		);
		const secondInstallment = installments.find(
			(schema) => schema.count > 1
		);

		setLabel(
			<>
				1x de{' '}
				{formatCurrency(
					(firstInstallment?.price || 0) - (coupon?.flatDiscount || 0)
				)}{' '}
				+ <br />
				{installments.length - 1}x de{' '}
				{formatCurrency(secondInstallment?.price || 0)}
			</>
		);
	}, [content, coupon]);

	const handleIncreaseQuantity = () => {
		setQuantity((prev) => prev + 1);
		setCurrentPrice((prev) => prev + (content?.pricing.price || 0));
	};

	const handleDecreaseQuantity = () => {
		if (quantity === 1) return;

		setQuantity((prev) => prev - 1);
		setCurrentPrice((prev) => prev - (content?.pricing.price || 0));
	};

	useEffect(() => {
		const discount = coupon?.flatDiscount || 0;
		const availableCoupons = (coupon?.amount || 0) - (coupon?.used || 0);

		const priceWithoutDiscount = (content?.pricing.price || 0) * quantity;

		availableCoupons >= quantity
			? setCurrentPrice(priceWithoutDiscount - discount * quantity)
			: setCurrentPrice(
					priceWithoutDiscount - discount * availableCoupons
			  );
	}, [quantity, coupon]);

	const [hasDiscount, setHasDiscount] = useState(false);
	useEffect(() => {
		if (coupon) return setHasDiscount(true);

		if (
			billingType === BillingType.CreditCard &&
			content?.pricing.flatCreditCardDiscount
		)
			return setHasDiscount(true);

		if (billingType === BillingType.Pix && content?.pricing.flatPixDiscount)
			return setHasDiscount(true);

		if (
			billingType === BillingType.Boleto &&
			content?.pricing.flatTicketDiscount
		)
			return setHasDiscount(true);

		return setHasDiscount(false);
	}, [coupon, billingType]);

	return (
		<div className="p-6 bg-white rounded-xl sidebar-grid-item">
			<div className="h-100 d-flex flex-column px-5">
				<Card.Header className="pt-md-6 py-0 pb-4 d-flex align-items-center justify-content-between bg-transparent px-0 border-0">
					<Card.Title className="mb-0">
						{customization && (
							<img
								src={
									isUrl(customization?.logo)
										? customization?.logo
										: `${customization?.url}${
												customization?.logo
										  }?d=${Date.now()}`
								}
								alt=""
								className="col-10 px-0"
							/>
						)}
					</Card.Title>
				</Card.Header>
				<Card.Body className="pt-10 pb-10 pb-md-5 px-0">
					{isLoading ? (
						<Row className="justify-content-center">
							<Spinner
								animation="border"
								variant="light"
								style={{
									height: '3rem',
									width: '3rem',
									borderWidth: '.4rem'
								}}
							/>
						</Row>
					) : (
						<>
							{content && (
								<>
									<div className="d-flex flex-column">
										{image && (
											<div
												style={{
													marginLeft: '-36px',
													marginRight: '-36px'
												}}
											>
												<img
													src={
														isUrl(
															`${image?.path}${image?.name}`
														)
															? `${image?.path}${image?.name}`
															: `${customization?.url}${image?.path}${image?.name}`
													}
													alt=""
													style={{
														maxHeight: '300px',
														objectFit: 'contain'
													}}
													className="w-100 mb-8"
												/>
											</div>
										)}
										<div className="text-left">
											<span className="d-block h3 mb-3 mb-md-6 text-gray-700">
												{content?.title || plan?.name}
											</span>
											{hasDiscount && (
												<del className="h4 font-weight-bold mt-3 text-danger d-block">
													{formatCurrency(
														((content?.pricing
															.price || 0) +
															(orderBump?.pricing
																.price || 0)) *
															quantity
													)}
												</del>
											)}
											<div className="d-flex justify-content-between align-items-start w-100 pr-2">
												<h4 className="my-0 h1 font-weight-bold text-gray-700">
													{label
														? label
														: formatCurrency(
																(currentPrice ||
																	0) +
																	(orderBump
																		?.pricing
																		.price ||
																		0)
														  )}
												</h4>
												{content.pricing
													.isBulkPurchaseEnabled && (
													<div className="row align-items-center justify-content-end">
														<span
															className="font-size-sm d-block font-weight-bold pl-0 col-12 mb-1 text-gray-700"
															style={{
																lineHeight:
																	'13px',
																textAlign: 'end'
															}}
														>
															Quantidade:
														</span>
														<button
															onClick={
																handleDecreaseQuantity
															}
															style={{
																backgroundColor:
																	currentMinusBg
															}}
															className="btn btn-icon btn-sm btn-transparent text-gray-700"
															onMouseEnter={() => {
																setCurrentMinusBg(
																	customization?.primaryColor ||
																		'unset'
																);
																setCurrentMinusColor(
																	'#fff'
																);
															}}
															onMouseOut={() => {
																setCurrentMinusBg(
																	'unset'
																);
																setCurrentMinusColor(
																	customization?.primaryColor ||
																		'unset'
																);
															}}
														>
															-
														</button>
														<h6 className="h5 mx-3 mb-0 text-gray-700">
															{quantity}
														</h6>
														<button
															onClick={
																handleIncreaseQuantity
															}
															style={{
																backgroundColor:
																	currentPlusBg
															}}
															onMouseEnter={() => {
																setCurrentPlusBg(
																	customization?.primaryColor ||
																		'unset'
																);
																setCurrentPlusColor(
																	'#fff'
																);
															}}
															onMouseOut={() => {
																setCurrentPlusBg(
																	'unset'
																);
																setCurrentPlusColor(
																	customization?.primaryColor ||
																		'unset'
																);
															}}
															className="btn btn-icon btn-sm btn-transparent text-gray-700"
														>
															+
														</button>
													</div>
												)}
											</div>
											{content && (
												<div className="d-flex justify-content-start mt-4">
													<Coupon />
												</div>
											)}
										</div>
									</div>
								</>
							)}
							{plans && (
								<div>
									<span className="h4 text-gray-800">
										Escolha o seu plano:
									</span>
									{plans.map((plan) => (
										<PlanButton key={plan.id} plan={plan} />
									))}
								</div>
							)}
						</>
					)}
				</Card.Body>
				<div className="d-none d-md-block justify-self-end mb-15 text-center text-muted">
					Powered by{' '}
					<span className="font-weight-bolder">Curseduca</span>
				</div>
			</div>
		</div>
	);
};
