import { useContext, useEffect, useState } from 'react';

import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { OrderBumpContext } from '../Context/OrderBumpContext';
import { useQuery } from 'react-query';
import { contentsApi } from '../../../../api/contents';
import { Checkbox } from '../../../../components/Inputs/Checkbox';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { isUrl } from '../../../../utils/validators/url';

export const OrderBump = () => {
	const { setOrderBump } = useContext(OrderBumpContext);
	const { customization } = useContext(ApplicationContext);

	const [uuid, setUuid] = useState<string>('');

	const queryParams = new URLSearchParams(window.location.search);

	const { data: content } = useQuery(
		['123', { uuid }],
		contentsApi.getOrderBump.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	useEffect(() => {
		const uuid = queryParams.get('order_bump');
		if (uuid) setUuid(uuid);
	}, []);

	const handleAddOrderBump = (e: React.FormEvent<HTMLInputElement>) => {
		if (!content) return;

		setOrderBump(e.currentTarget.checked ? content : null);
	};

	return (
		<>
			{content && (
				<div className="d-flex align-items-center mt-5 px-3 py-5">
					<Checkbox
						label=""
						wrapperClassName="checkbox-lg"
						onChange={(e) => handleAddOrderBump(e)}
					/>
					<div className="symbol-list d-flex flex-wrap">
						{content.images.length > 1 && (
							<div className="symbol symbol-60 symbol-2by3 mr-3">
								<div
									className="symbol-label"
									style={{
										backgroundImage: isUrl(
											`${content?.images?.[0]?.path}${content?.images?.[0]?.name}`
										)
											? `url('${content?.images?.[0]?.path}${content?.images?.[0]?.name}')`
											: `url('${customization?.url}${content?.images?.[0]?.path}${content?.images?.[0]?.name}')`
									}}
								></div>
							</div>
						)}
					</div>
					<div>
						<p className="h6 text-gray-800">{content?.title}</p>
						<p className="text-muted mb-0">{content?.title}</p>
					</div>
					<div className="ml-auto">
						<p className="h4 font-weight-bold text-gray-800">
							{formatCurrency(content?.pricing.price)}
						</p>
					</div>
				</div>
			)}
		</>
	);
};
