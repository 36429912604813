import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';

import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

export const useGoogleAnalytics = () => {
	const [pageView, setPageView] = useState<() => void>(() => () => false);
	const [beginCheckout, setBeginCheckout] = useState<
		(input: { value: number }) => void
	>(() => () => false);
	const [addPaymentInfo, setAddPaymentInfo] = useState<
		(input: { value: number }) => void
	>(() => () => false);
	const [selectItem, setSelectItem] = useState<
		(input: { item: { name: string; price: number; id: number } }) => void
	>(() => () => false);

	const [id, setId] = useState<string>();

	const { customization } = useContext(ApplicationContext);

	useEffect(() => {
		if (id) return;

		if (!customization?.url) return;

		axios
			.get<{ id: string }>(`${customization?.url || ''}/integrations/ga`)
			.then((response) => {
				if (!response?.data?.id) return;

				if (response.data.id.startsWith('GTM-')) return;
				setId(response.data.id);
			})
			.catch(() => {
				throw new Error('Error while fetch Analytics Pixel.');
			});
	}, [customization]);

	useEffect(() => {
		if (!id) return;

		if (id.startsWith('G-')) {
			ReactGA4.initialize(id);
			setPageView(
				() => () =>
					ReactGA4.send({
						hitType: 'pageview',
						page: window.location.pathname + window.location.search,
						title: 'Custom Title'
					})
			);
			setBeginCheckout(
				() =>
					({ value }: { value: number }) =>
						ReactGA4.event('begin_checkout', {
							currency: 'BRL',
							value,
							items: []
						})
			);
			setAddPaymentInfo(
				() =>
					({ value }: { value: number }) =>
						ReactGA4.event('add_payment_info', {
							currency: 'BRL',
							value,
							items: []
						})
			);
			setSelectItem(
				() =>
					({
						item
					}: {
						item: { name: string; price: number; id: number };
					}) =>
						ReactGA4.event('select_item', {
							items: [
								{
									item_id: String(item.id || ''),
									item_name: item.name,
									price: item.price
								}
							]
						})
			);
		} else {
			ReactGA.initialize(id);
			setPageView(
				() => () =>
					ReactGA.pageview(
						window.location.pathname + window.location.search
					)
			);
		}
	}, [id]);

	return { pageView, beginCheckout, addPaymentInfo, selectItem };
};
