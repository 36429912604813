export const formatCurrency = (value: number) => {
	return new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL'
	}).format(value);
};

export const unformatCurrency = (value: string | number): number => {
	if (typeof value === 'number') return value;

	const unformattedValue = value
		.replace('R$', '')
		.replaceAll('.', '')
		.replaceAll(',', '.')
		.trim();

	return Number(unformattedValue);
};
