import { apiFactory } from '../ApiFactory';
import { ConfirmPaymentDto } from './types/confirm-payment.dto';
import { OpenPaymentDto } from './types/open-payment.dto';
import { IPayment } from './types/payment.type';

const open = async (data: OpenPaymentDto): Promise<IPayment> => {
	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).post<IPayment>('/payments', data);

	return response.data;
};

const confirm = async ({
	paymentId,
	...data
}: ConfirmPaymentDto): Promise<IPayment> => {
	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).patch<IPayment>(`/payments/${paymentId}/confirm`, data);

	return response.data;
};

export const paymentsApi = {
	confirm,
	open
};
