import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';
import { IPlan } from './interfaces/planInterface';

type IFindByReference = [string, { reference: string }];
type OutputListPlans = { data: IPlan[] };

const list: QueryFunction<OutputListPlans, IFindByReference> = async ({
	queryKey
}) => {
	const [_, { reference }] = queryKey;

	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<OutputListPlans>(`/plans`, { params: { reference } });

	return response.data;
};

const findByReference: QueryFunction<IPlan, IFindByReference> = async ({
	queryKey
}) => {
	const [_, { reference }] = queryKey;

	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<IPlan>(`/plans/reference/${reference}`);

	return response.data;
};

export const plansApi = {
	findByReference: {
		key: 'FIND_PLAN_BY_REFERENCE',
		fetch: findByReference
	},
	list: {
		key: 'LIST_PLANS',
		fetch: list
	}
};
