import { createContext } from 'react';
import { ICheckout } from '../../../../api/checkouts/types/checkout.type';
import { IContent } from '../../../../api/contents/interfaces/contentInterface';
import { BillingType } from '../constants/billingType';

interface IContentContext {
	content?: IContent;
	currentPrice?: number;
	setCurrentPrice: React.Dispatch<React.SetStateAction<number>>;
	quantity: number;
	setQuantity: React.Dispatch<React.SetStateAction<number>>;
	isLoading: boolean;

	billingType: BillingType;
	setBillingType: React.Dispatch<React.SetStateAction<BillingType>>;

	checkout?: ICheckout;
}

export const ContentContext = createContext<IContentContext>({
	isLoading: false,
	setCurrentPrice: () => false,
	quantity: 1,
	setQuantity: () => false,
	billingType: BillingType.CreditCard,
	setBillingType: () => false
});
