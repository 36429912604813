import { useContext, useRef, useState, useEffect } from 'react';
import { InputGroup, FormControl, Button, Spinner } from 'react-bootstrap';

import { ContentContext } from '../Context/ContentContext';
import { useQuery } from 'react-query';
import { CouponContext } from '../Context/CouponContext';
import { TrashIcon } from '../../../../components/icons/TrashIcon';
import { toast } from '../../../../utils/toast';
import { couponsApi } from '../../../../api/coupons';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { apiFactory } from '../../../../api/ApiFactory';
import { ICoupon } from '../../../../api/coupons/interfaces/couponInterface';

const { getByCodeAndContent } = couponsApi;

export const Coupon = () => {
	const [code, setCode] = useState<string>('');

	const { content, quantity } = useContext(ContentContext);
	const { setCoupon, coupon } = useContext(CouponContext);

	const couponRef = useRef<HTMLInputElement>(null);

	const [isCouponInputVisible, setIsCouponInputVisible] = useState(false);

	const findCouponApi = useQuery(
		[getByCodeAndContent.key, { contentId: content?.id, code }],
		getByCodeAndContent.fetch,
		{
			enabled: false
		}
	);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const code = queryParams.get('coupon');
		if (code) setCode(code);
	}, []);

	useEffect(() => {
		if (!code) return;

		apiFactory
			.getInstance()
			.getCurseducaPayClient()
			.then((client) => {
				client
					.get<ICoupon>(`/coupons/${code}/${content?.id}`)
					.then(function ({ data }) {
						if (!data || !data?.isActive)
							return toast.error('Cupom inválido.');

						setCoupon(data);
						toast.success('Cupom aplicado com sucesso!');
					})
					.catch(function () {
						setCode('');
						return toast.error('Cupom inválido.');
					});
			});
	}, [code]);

	const handleCouponApply = (value: string | undefined) => {
		if (!value || !content?.id) return;
		setCode(value);
	};

	const handleRemoveCoupon = () => {
		setCode('');
		setIsCouponInputVisible(false);
		setCoupon(null);
	};

	return (
		<div className="d-flex flex-column align-items-center m-0">
			{isCouponInputVisible ? (
				<InputGroup className="mb-2 mt-4">
					<FormControl
						className="border-gray-500 text-gray-800"
						style={{ background: 'none' }}
						placeholder="Insira o cupom"
						ref={couponRef}
					/>
					<InputGroup.Append>
						{coupon ? (
							<Button
								variant="danger"
								size="sm"
								onClick={handleRemoveCoupon}
							>
								<span className="svg-icon svg-icon-lg">
									<TrashIcon />
								</span>
							</Button>
						) : (
							<Button
								style={{ width: '70px' }}
								disabled={
									findCouponApi.isLoading ? true : false
								}
								variant="primary"
								size="sm"
								onClick={() =>
									handleCouponApply(couponRef.current?.value)
								}
							>
								{findCouponApi.isLoading ? (
									<Spinner animation="border" size="sm" />
								) : (
									'Aplicar'
								)}
							</Button>
						)}
					</InputGroup.Append>
				</InputGroup>
			) : (
				<a
					href="#"
					className="font-size-md text-gray-700 border border-gray-600 b p-3 rounded"
					onClick={() => setIsCouponInputVisible(true)}
				>
					Adicionar cupom de desconto
				</a>
			)}
			{coupon && quantity + coupon.used > coupon.amount && (
				<span className="text-danger">
					Apenas {coupon.amount - coupon.used}{' '}
					{coupon.amount - coupon.used - quantity >= 0
						? 'cupons disponíveis e serão aplicados'
						: 'cupom disponível e será aplicado'}
					disponíveis e serão aplicados
				</span>
			)}
		</div>
	);
};
