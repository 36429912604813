import TextField from '@mui/material/TextField';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { ITextInputProps } from '../TextInputPropsInterface';

type Mask = {
	placeholder: string;
	mask: string;
};

type IsForeign = {
	isForeign: boolean;
};

const CpfCnpjInput = forwardRef(
	({ onChange, onBlur, ...props }: ITextInputProps & IsForeign, ref) => {
		const [{ mask, placeholder }, setMask] = useState<Mask>({
			placeholder: '___.___.___-__',
			mask: '999.999.999.99'
		});

		const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
			if (onChange) onChange(event);

			const value = event.currentTarget.value.replace(/\D/g, '');

			if (value.length > 11) {
				return setMask({
					mask: '99.999.999/9999-99',
					placeholder: '__.___.___.____-__'
				});
			}

			return setMask({
				placeholder: '___.___.___-__ ',
				mask: '999.999.999.999'
			});
		};

		return (
			<InputMask
				mask={mask}
				maskPlaceholder={placeholder}
				onChange={handleOnChange}
				onBlur={onBlur}
			>
				<TextField
					inputRef={ref}
					type="text"
					placeholder={'Seu CPF ou CNPJ'}
					label={'CPF/CNPJ'}
					className="w-100"
					{...props}
				/>
			</InputMask>
		);
	}
);

CpfCnpjInput.displayName = 'CpfCnpjInput';

export { CpfCnpjInput };
