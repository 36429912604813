import { createContext } from 'react';
import { ICoupon } from '../../../../api/coupons/interfaces/couponInterface';

interface ICouponContext {
	coupon?: ICoupon | null;
	setCoupon: (coupon: ICoupon | null) => void;
}

export const CouponContext = createContext<ICouponContext>({
	setCoupon: () => false
});
