import { createContext } from 'react';
import { ISubscription } from '../../../../api/subscriptions/types/subscription.type';

interface ISubscriptionContext {
	subscription: ISubscription | null;
	setSubscription: (subscription: ISubscription) => void;
}

export const SubscriptionContext = createContext<ISubscriptionContext>(
	{} as ISubscriptionContext
);
