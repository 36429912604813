import axios from 'axios';

export type TokenizeCreditCardDto = {
	hasAntifraud: boolean;
	type: 'card';
	card: {
		number: string;
		holder_name: string;
		exp_month: number;
		exp_year: number;
		cvv: string;
	};
};

export type TokenizeCreditCardResponse = {
	id: string;
	type: 'card';
	created_at: string;
	expires_at: string;
	card: {
		first_six_digits: string;
		last_four_digits: string;
		holder_name: string;
		exp_month: number;
		exp_year: number;
		brand: string;
	};
};

export const tokenize = async ({
	hasAntifraud,
	...data
}: TokenizeCreditCardDto) => {
	const today = new Date();
	const expiryDate = new Date();
	expiryDate.setFullYear(
		2000 + Number(data.card.exp_year),
		Number(data.card.exp_month),
		1
	);

	if (expiryDate < today) {
		throw new Error('Cartão de informado está expirado.');
	}

	const publicKey = hasAntifraud
		? process.env.REACT_APP_PAGARME_PUBLIC_KEY
		: process.env.REACT_APP_PAGARME_PUBLIC_KEY_WITHOUT_ANTIFRAUD;

	const response = await axios.post<TokenizeCreditCardResponse>(
		`https://api.pagar.me/core/v5/tokens?appId=${publicKey}`,
		data
	);

	return response.data;
};
