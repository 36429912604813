import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Box } from '@mui/system';
import { Layout4Icon } from '../../components/icons/Layout4Icon';
import { PlusIcon } from '../../components/icons/PlusIcon';
import { OtherVertical } from '../../components/icons/OtherVertical';
import { SearchIcon } from '../../components/icons/SearchIcon';
import { BellIcon } from '../../components/icons/BellIcon';
import { StarIcon } from '../../components/icons/StarIcon';
import { GroupIcon } from '../../components/icons/GroupIcon';
import { UserIcon } from '../../components/icons/UserIcon';
import { SettingsIcon } from '../../components/icons/SettingsIcon';
import { AddUserIcon } from '../../components/icons/AddUserIcon';

export const Chat = () => {
	return (
		<Grid container columns={24} height="100vh">
			<Grid
				justifyContent="space-between"
				alignItems="center"
				display="flex"
				flexDirection="column"
				sx={{ backgroundColor: '#15172E' }}
				xs={1}
				py={3}
				item
			>
				<div className="px-4">
					<div className="mb-5">
						<img src="/logo.jpg" className="w-100 rounded" />
					</div>
					<a
						href="/courses"
						className="d-flex justify-content-center"
					>
						<span className="svg-icon svg-icon-primary svg-icon-2x">
							<Layout4Icon />
						</span>
					</a>
					<a
						href="/courses"
						className="d-flex justify-content-center mt-4"
					>
						<span className="svg-icon svg-icon-primary svg-icon-2x opacity-50">
							<BellIcon />
						</span>
					</a>
					<a
						href="/courses"
						className="d-flex justify-content-center mt-4 opacity-50"
					>
						<span className="svg-icon svg-icon-primary svg-icon-2x">
							<StarIcon />
						</span>
					</a>
					<a
						href="/courses"
						className="d-flex justify-content-center mt-4 opacity-50"
					>
						<span className="svg-icon svg-icon-primary svg-icon-3x">
							<UserIcon />
						</span>
					</a>
					<div
						className="separator separator-solid my-5 w-100"
						style={{ borderColor: '#777' }}
					></div>
				</div>
				<div
					className="d-flex flex-column justify-content-start"
					style={{ flexGrow: 1 }}
				>
					<div
						className="my-3"
						style={{
							borderLeft: '6px solid #5e9fd2',
							backgroundColor: '#1d2142'
						}}
					>
						<div
							className="px-6 py-2"
							style={{
								marginLeft: '-6px'
							}}
						>
							<img
								src="/empreendedorando_logo.jpeg"
								className="w-100 rounded"
							/>
						</div>
					</div>
					<div className="my-3">
						<div className="px-6">
							<img
								src="/sportplus_logo.jpeg"
								className="w-100 rounded"
							/>
						</div>
					</div>

					<div className="my-3 d-flex justify-content-center">
						<span className="svg-icon svg-icon-primary svg-icon-2x">
							<PlusIcon />
						</span>
					</div>
				</div>

				<div className="px-4">
					<div
						className="separator separator-solid my-5 w-100"
						style={{ borderColor: '#777' }}
					></div>
					<div>
						<img src="joao_photo.jpeg" className="w-100 rounded" />
					</div>
				</div>
			</Grid>
			<Grid item sx={{ backgroundColor: '#1d2142' }} py={2} px={2} xs={4}>
				<Box className="px-2">
					<div className="mb-2">
						<Typography
							className="text-muted h6 text-uppercase"
							fontWeight="bold"
						>
							Comunidade
						</Typography>
					</div>
					<Typography
						color="whitesmoke"
						fontWeight="bold"
						className="h2"
					>
						Empreendedorando
					</Typography>
				</Box>
				<Box className="mt-5">
					<ul className="list-unstyled">
						<li className="mb-4 px-2 py-2">
							<h4 className="text-white mb-0"># Geral</h4>
						</li>
						<li
							className="my-4 px-2 py-2 rounded d-flex justify-content-between align-items-center"
							style={{ backgroundColor: '#303770' }}
						>
							<h4 className="text-white mb-0"># Empreenday</h4>
							<div>
								<span className="svg-icon svg-icon-primary opacity-50">
									<AddUserIcon />
								</span>
								<span className="svg-icon svg-icon-primary opacity-50 ml-2">
									<SettingsIcon />
								</span>
							</div>
						</li>
						<li className="my-4 px-2 py-2">
							<h4 className="text-white mb-0"># Network+</h4>
						</li>
						<li className="my-4 px-2 py-2">
							<h4 className="text-white mb-0">
								# Empreendedoria
							</h4>
						</li>
						<li className="my-4 px-2 py-2">
							<h4 className="text-white mb-0"># Estratégia</h4>
						</li>
					</ul>
				</Box>
			</Grid>
			<Grid xs={19} item style={{ maxHeight: '100vh' }}>
				<Box
					bgcolor={'#242954'}
					height={1}
					display="flex"
					flexDirection="column"
					pb={3}
				>
					<div
						style={{
							height: '60px',
							borderBottom: '2px solid #303770'
						}}
						className="d-flex justify-content-between align-items-center px-10 py-4"
					>
						<h2 className="text-white mb-0"># Empreenday</h2>
						<div className="d-flex align-items-center">
							<span className="svg-icon svg-icon-primary svg-icon-2x mr-5">
								<GroupIcon />
							</span>
							<div className="bg-dark w-200px py-2 px-4 rounded d-flex justify-content-between text-white">
								Buscar
								<span className="svg-icon svg-icon-primary">
									<SearchIcon />
								</span>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column justify-content-end flex-grow-1 px-10 w-100">
						<div className="mb-15">
							<div className="d-flex align-items-start justify-content-between">
								<div className="d-flex align-items-start position-relative">
									<div className="symbol symbol-60 symbol-circle mr-5">
										<img
											src="/rafael_photo.jpeg"
											className="align-self-end"
											alt=""
										/>
									</div>

									<div className="d-flex flex-grow-1">
										<div className="d-flex flex-column flex-grow-1">
											<div className="font-weight-bold font-size-lg mb-1">
												<a
													href="#"
													className="text-primary text-hover-primary"
												>
													Rafael Melo
												</a>
												<span className="text-muted small ml-5 font-weight-bold">
													Há 15 minutos
												</span>
											</div>
											<div className="text-white">
												<p>
													Quando será o próximo
													Empreenday?
												</p>
											</div>
										</div>
									</div>
								</div>
								<div>
									<span className="svg-icon svg-icon-primary svg-icon-2x">
										<OtherVertical />
									</span>
								</div>
							</div>
						</div>
						<div className="mb-15">
							<div className="d-flex align-items-start justify-content-between">
								<div className="d-flex align-items-start position-relative">
									<div className="symbol symbol-60 symbol-circle mr-5">
										<img
											src="/camila_photo.jpg"
											className="align-self-end"
											alt=""
										/>
									</div>

									<div className="d-flex flex-grow-1">
										<div className="d-flex flex-column flex-grow-1">
											<div className="font-weight-bold font-size-lg mb-1">
												<a
													href="#"
													className="text-primary text-hover-primary"
												>
													Camila Colonese
												</a>
												<span className="text-muted small ml-5 font-weight-bold">
													Há 12 minutos
												</span>
											</div>
											<div className="text-white">
												<p>
													<span className="text-primary font-weight-bold">
														@Rafael Melo
													</span>{' '}
													fecharemos a data na próxima
													semana
												</p>
											</div>
										</div>
									</div>
								</div>
								<div>
									<span className="svg-icon svg-icon-primary svg-icon-2x">
										<OtherVertical />
									</span>
								</div>
							</div>
						</div>
						<div className="mb-15">
							<div className="d-flex align-items-start justify-content-between">
								<div className="d-flex align-items-start position-relative">
									<div className="symbol symbol-60 symbol-circle mr-5">
										<img
											src="/joao_photo.jpeg"
											className="align-self-end"
											alt=""
										/>
									</div>

									<div className="d-flex flex-grow-1">
										<div className="d-flex flex-column flex-grow-1">
											<div className="font-weight-bold font-size-lg mb-1">
												<a
													href="#"
													className="text-primary text-hover-primary"
												>
													João Carrilho
												</a>
												<span className="label label-inline label-outline-primary ml-4">
													Moderador
												</span>
												<span className="text-muted small ml-5 font-weight-bold">
													Há 11 minutos
												</span>
											</div>
											<div className="text-white">
												<p>
													Estamos finalizando a página
													de vendas
												</p>
											</div>
										</div>
									</div>
								</div>
								<div>
									<span className="svg-icon svg-icon-primary svg-icon-2x">
										<OtherVertical />
									</span>
								</div>
							</div>
						</div>
						<div className="mb-15">
							<div className="d-flex align-items-start justify-content-between">
								<div className="d-flex align-items-start position-relative">
									<div className="symbol symbol-60 symbol-circle mr-5">
										<img
											src="/samuel_photo.jpg"
											className="align-self-end"
											alt=""
										/>
										<img
											src="/medal.png"
											className="position-absolute"
											style={{
												width: '28px',
												height: 'auto',
												right: '-8px',
												bottom: '-8px'
											}}
										/>
									</div>

									<div className="d-flex flex-grow-1">
										<div className="d-flex flex-column flex-grow-1">
											<div className="font-weight-bold font-size-lg mb-1">
												<a
													href="#"
													className="text-primary text-hover-primary"
												>
													Samuel Sena
												</a>
												<span className="text-muted small ml-5 font-weight-bold">
													Há 3 minutos
												</span>
											</div>
											<div className="text-white">
												<p>
													<span className="text-primary font-weight-bold">
														@João Carrilho
													</span>{' '}
													aproveitando, você não me
													entregou a copy dos
													criativos
												</p>
											</div>
										</div>
									</div>
								</div>
								<div>
									<span className="svg-icon svg-icon-primary svg-icon-2x">
										<OtherVertical />
									</span>
								</div>
							</div>
						</div>
						<div className="mb-15">
							<div className="d-flex align-items-start justify-content-between">
								<div className="d-flex align-items-start position-relative">
									<div className="symbol symbol-60 symbol-circle mr-5">
										<img
											src="/samuel_photo.jpg"
											className="align-self-end"
											alt=""
										/>
										<img
											src="/medal.png"
											className="position-absolute"
											style={{
												width: '28px',
												height: 'auto',
												right: '-8px',
												bottom: '-8px'
											}}
										/>
									</div>

									<div className="d-flex flex-grow-1">
										<div className="d-flex flex-column flex-grow-1">
											<div className="font-weight-bold font-size-lg mb-1">
												<a
													href="#"
													className="text-primary text-hover-primary"
												>
													Samuel Sena
												</a>
												<span className="text-muted small ml-5 font-weight-bold">
													Há 3 minutos
												</span>
											</div>
											<div className="text-white">
												<p>
													Só falta isso para
													finalizarmos
												</p>
											</div>
										</div>
									</div>
								</div>
								<div>
									<span className="svg-icon svg-icon-primary svg-icon-2x">
										<OtherVertical />
									</span>
								</div>
							</div>
						</div>
						<div className="mb-15">
							<div className="d-flex align-items-start justify-content-between">
								<div className="d-flex align-items-start position-relative">
									<div className="symbol symbol-60 symbol-circle mr-5">
										<img
											src="/samuel_photo.jpg"
											className="align-self-end"
											alt=""
										/>
										<img
											src="/medal.png"
											className="position-absolute"
											style={{
												width: '28px',
												height: 'auto',
												right: '-8px',
												bottom: '-8px'
											}}
										/>
									</div>

									<div className="d-flex flex-grow-1">
										<div className="d-flex flex-column flex-grow-1">
											<div className="font-weight-bold font-size-lg mb-1">
												<a
													href="#"
													className="text-primary text-hover-primary"
												>
													Samuel Sena
												</a>
												<span className="text-muted small ml-5 font-weight-bold">
													Há 1 minuto
												</span>
											</div>
											<div className="text-white">
												<p>E bora moer!</p>
											</div>
										</div>
									</div>
								</div>
								<div>
									<span className="svg-icon svg-icon-primary svg-icon-2x">
										<OtherVertical />
									</span>
								</div>
							</div>
						</div>
						<div>
							<textarea
								rows={1}
								placeholder="Conversar em #Empreenday"
								className="form-control border-0 resize-none"
								style={{
									height: '48px',
									overflowY: 'hidden',
									backgroundColor: '#303770',
									lineHeight: '32px'
								}}
							></textarea>
						</div>
					</div>
				</Box>
			</Grid>
		</Grid>
	);
};
