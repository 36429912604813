import { createContext } from 'react';
import { IPayment } from '../../../../api/payments/types/payment.type';

interface IPaymentContext {
	payment?: IPayment | null;
	setPayment: (payment: IPayment) => void;
}

export const PaymentContext = createContext<IPaymentContext>({
	setPayment: () => false
});
