import { createContext } from 'react';

interface IFbPixelContext {
	fbPixel?: string;
	setFbPixel: (pixel: string) => void;
}

export const FbPixelContext = createContext<IFbPixelContext>({
	setFbPixel: () => false
});
