import { forwardRef, HTMLProps } from 'react';

interface IProps extends HTMLProps<HTMLInputElement> {
	label: string;
	wrapperClassName?: string;
}

const Checkbox = forwardRef<HTMLInputElement, IProps>(
	({ label, wrapperClassName, ...props }, ref) => {
		return (
			<label className={`text-gray-800 checkbox ${wrapperClassName}`}>
				<input type="checkbox" {...props} ref={ref} />
				<span style={{ minWidth: '18px' }} className="mr-3"></span>
				{label}
			</label>
		);
	}
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
