import TextField from '@mui/material/TextField';
import { useContext, useEffect, Fragment, useState, useRef } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Inputs } from '../Form/inputs';

import { useQuery } from 'react-query';
import {
	CreditCardExpirationInput,
	CreditCardNumberInput,
	HolderNameInput
} from '../../../../components/Inputs/CreditCardInput';
import { creditCardsApi } from '../../../../api/creditCards';
import { ConnectForm } from '../../../../shared/ConnectForm';
import { Checkbox } from '../../../../components/Inputs/Checkbox';
import { ContentContext } from '../Context/ContentContext';
import { unformatCurrency } from '../../../../utils/formatCurrency';
import { CurrencyInput } from '../../../../components/Inputs/CurrencyInput';

const { list } = creditCardsApi;

export const CreditCardForm = () => {
	const isFirstFocus = useRef(true);
	const { content, currentPrice } = useContext(ContentContext);

	const [missingValue, setMissingValue] = useState<number>(currentPrice || 0);

	const output = useWatch<Inputs, 'creditCards'>({
		name: 'creditCards'
	});

	useEffect(() => {
		if (!currentPrice || !output) return;

		const totalValue = output.reduce(
			(previousValue, { flatAmount }) => previousValue + flatAmount,
			0
		);

		setMissingValue(currentPrice - totalValue);
	}, [output]);

	const { fields, append, remove } = useFieldArray<Inputs>({
		name: 'creditCards'
	});

	const listCreditCardsApi = useQuery(list.key, list.fetch);

	useEffect(() => {
		remove();
		append({});
	}, []);

	const handleAddCreditCard = () => {
		if (fields.length < 2) append({});
	};

	return (
		<>
			<ConnectForm>
				{({ register, formState: { errors }, watch, setFocus }) => {
					return (
						<Row>
							{fields.map((field, index) => (
								<Fragment key={field.id}>
									<Col xs={12}>
										{!output?.[index]?.isNewCreditCard &&
										listCreditCardsApi.data?.data &&
										listCreditCardsApi.data?.data.length >
											0 ? (
											<>
												{listCreditCardsApi.data
													?.data &&
													listCreditCardsApi.data
														?.data.length > 0 && (
														<>
															<div className="d-flex align-items-center justify-content-between">
																<h4 className="h6 my-5">
																	Selecione um
																	cartão de
																	crédito já
																	cadastrado
																</h4>
																{fields.length >
																	1 && (
																	<a
																		href="#"
																		className="text-danger"
																		onClick={() =>
																			remove(
																				index
																			)
																		}
																	>
																		Remover
																		cartão
																	</a>
																)}
															</div>
															<Row className="mx-0">
																<Form.Group
																	as={Col}
																	xs={12}
																	className="px-0"
																>
																	<Form.Control
																		as="select"
																		{...register(
																			`creditCards.${index}.creditCard.id`
																		)}
																		value=""
																	>
																		{listCreditCardsApi.data?.data.map(
																			({
																				id,
																				flag,
																				number
																			}) => (
																				<option
																					key={
																						id
																					}
																					value={
																						id
																					}
																				>
																					{`${flag} - **** ${number}`}
																				</option>
																			)
																		)}
																	</Form.Control>
																</Form.Group>
															</Row>
														</>
													)}
											</>
										) : (
											<>
												<div className="d-flex align-items-center justify-content-between">
													<h4 className="my-5 my-md-8 h4 text-gray-800">
														Dados do cartão de
														crédito
													</h4>
													{fields.length > 1 && (
														<a
															href="#"
															className="text-danger"
															onClick={() =>
																remove(index)
															}
														>
															Remover cartão
														</a>
													)}
												</div>
												<Row>
													<Col
														xs={12}
														md={6}
														className="mb-5 pr-md-1"
													>
														<CreditCardNumberInput
															className="w-100"
															onFocus={() => {
																if (
																	isFirstFocus.current
																) {
																	setFocus(
																		'name'
																	);
																	isFirstFocus.current =
																		false;
																}
															}}
															{...register(
																`creditCards.${index}.creditCard.number`,
																{
																	pattern:
																		/[0-9]+/
																}
															)}
															error={
																!!errors
																	.creditCard
																	?.number
															}
														/>
													</Col>
													<Col
														md={6}
														className="mb-5 pl-md-1"
													>
														<HolderNameInput
															type="text"
															className="w-100"
															{...register(
																`creditCards.${index}.creditCard.holderName`,
																{
																	pattern:
																		/[A-Za-z]+/
																}
															)}
															error={
																!!errors
																	.creditCard
																	?.holderName
															}
															helperText={
																errors
																	.creditCard
																	?.holderName
																	?.message
															}
														/>
													</Col>
													<Col
														xs={6}
														className="mb-5 pr-md-1"
													>
														<CreditCardExpirationInput
															className="w-100"
															type="text"
															{...register(
																`creditCards.${index}.creditCard.expiry`
															)}
															isForeign={watch(
																'isForeign'
															)}
														/>
													</Col>
													<Col
														xs={6}
														className="mb-5 pl-md-1"
													>
														<TextField
															className="w-100"
															type="text"
															label="CVV*"
															{...register(
																`creditCards.${index}.creditCard.cvv`,
																{
																	pattern:
																		/[0-9]+/
																}
															)}
															placeholder="CVV"
															error={
																!!errors
																	.creditCard
																	?.cvv
															}
															helperText={
																errors
																	.creditCard
																	?.cvv
																	?.message
															}
														/>
													</Col>

													{fields.length > 1 && (
														<Col
															md={6}
															className="pr-md-1 mb-5"
														>
															<CurrencyInput
																{...register(
																	`creditCards.${index}.flatAmount`,
																	{
																		max: {
																			value:
																				(output?.[
																					index
																				]
																					?.flatAmount ||
																					0) +
																				missingValue,
																			message: `A soma dos valores dos cartões deve ser igual a ${currentPrice}.`
																		},
																		min: {
																			value:
																				(output?.[
																					index
																				]
																					?.flatAmount ||
																					0) +
																				missingValue,
																			message: `A soma dos valores dos cartões deve ser igual a ${currentPrice}.`
																		},
																		setValueAs:
																			(
																				value: string
																			):
																				| number
																				| string =>
																				value &&
																				unformatCurrency(
																					value
																				)
																	}
																)}
																error={
																	!!errors
																		.creditCards?.[
																		index
																	]
																		?.flatAmount
																}
																className="w-100"
															/>
														</Col>
													)}
												</Row>
											</>
										)}
										{listCreditCardsApi.data?.data &&
										listCreditCardsApi.data?.data.length >
											0 ? (
											<Checkbox
												label="Cadastrar novo cartão"
												{...register(
													`creditCards.${index}.isNewCreditCard`
												)}
											/>
										) : (
											<Form.Control
												type="hidden"
												{...register(
													`creditCards.${index}.isNewCreditCard`,
													{
														value: true
													}
												)}
											/>
										)}
									</Col>
								</Fragment>
							))}
						</Row>
					);
				}}
			</ConnectForm>
			{content?.pricing.isMultipleCreditCardEnabled && fields.length < 2 && (
				<Row className="justify-content-center mt-5">
					<Button variant="light" onClick={handleAddCreditCard}>
						Pagar com 2 cartões
					</Button>
				</Row>
			)}
		</>
	);
};
