import axios from 'axios';

export type CreateMemberDto = {
	name: string;
	email: string;
	phone: string;

	metadata?: {
		url?: string;
	};
};

const create = ({ metadata, ...data }: CreateMemberDto) => {
	const url = metadata?.url || '';

	return axios.post<{ access_token: string }>(`${url}/user`, data, {
		withCredentials: true
	});
};

export const membersApi = {
	create
};
