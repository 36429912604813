import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { creditCardsApi } from '../../../../api/creditCards';
import { customersApi } from '../../../../api/customers';
import { paymentsApi } from '../../../../api/payments';
import { ConfirmPaymentDto } from '../../../../api/payments/types/confirm-payment.dto';
import { subscriptionsApi } from '../../../../api/subscriptions';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { BillingType } from '../constants/billingType';
import { ContentContext } from '../Context/ContentContext';
import { CouponContext } from '../Context/CouponContext';
import { OrderBumpContext } from '../Context/OrderBumpContext';
import { PaymentContext } from '../Context/PaymentContext';
import { PlanContext } from '../Context/PlanContext';
import { SubscriptionContext } from '../Context/SubscriptionContext';
import { Address, CreditCard, Inputs } from '../Form/inputs';

interface IHandleSubmit extends Inputs {
	billingType: number;
}

export const useSubmit = () => {
	const [searchParams] = useSearchParams();

	const { orderBump } = useContext(OrderBumpContext);
	const { finance } = useContext(ApplicationContext);
	const { plan } = useContext(PlanContext);
	const { content, quantity } = useContext(ContentContext);
	const { coupon } = useContext(CouponContext);
	const { payment } = useContext(PaymentContext);
	const { subscription } = useContext(SubscriptionContext);

	const createSubscriptionApi = useMutation(subscriptionsApi.create.fetch);
	const confirmPaymentApi = useMutation(paymentsApi.confirm);
	const createCustomerApi = useMutation(customersApi.create);
	const tokenizeCreditCardApi = useMutation(creditCardsApi.tokenize);

	const handle = async ({
		creditCards,
		address,
		billingType,
		isForeign,
		...data
	}: IHandleSubmit) => {
		if (!payment && !plan) throw new Error('Pagamento não encontrado.');

		if (address) address.postalcode = address.postalcode.replace(/\D/g, '');

		const [countryCode, areaCode, number] = data.phone.split(' ');
		const cpfCnpj = data.cpfCnpj.replace(/\D/g, '');

		await createCustomerApi.mutateAsync({
			name: data.name,
			email: data.email,
			isForeign: isForeign ?? false,
			cpfCnpj,
			phones: {
				mobilePhone: {
					countryCode,
					areaCode,
					number
				}
			}
		});

		if (!content && !plan) throw new Error('Conteúdo inválido.');

		const getInstallmentCount = () => {
			if (!data.installmentCount) return 1;

			if (content && !content?.pricing.isInstallmentEnabled) return 1;

			if (billingType === BillingType.CreditCard)
				return data.installmentCount;

			if (!finance?.isBoletoNPixInstallmentEnabled) return 1;

			return data.installmentCount;
		};

		const installmentCount = getInstallmentCount();

		const createPaymentData: ConfirmPaymentDto = {
			quantity,
			paymentId: 1,
			billingTypeId: billingType,
			phone: data.phone,
			address: finance?.isAddressRequired ? address : undefined,
			contentId: content?.id || 1,
			cpfCnpj,
			coupon_code: coupon?.code,
			upsell_code: searchParams.get('upsell_code') || undefined,
			affiliate_code: searchParams.get('affiliate_code') || undefined,
			click: searchParams.get('click') || undefined,
			installmentCount,
			order_bump: orderBump
				? searchParams.get('order_bump') || undefined
				: undefined
		};

		const isFree =
			(content?.pricing.price || 0) - (coupon?.flatDiscount || 0) === 0 &&
			content;

		if (billingType === BillingType.CreditCard && !isFree) {
			createPaymentData.creditCards = await Promise.all(
				handleCreditCard(creditCards, address, isForeign, cpfCnpj)
			);

			if (plan) {
				if (!createPaymentData.creditCards?.[0]) {
					throw new Error('Cartão de crédito não informado.');
				}

				const queryParams = new URLSearchParams(window.location.search);

				const customTrialDays = queryParams.get('t') || null;

				return await createSubscriptionApi.mutateAsync({
					id: subscription?.id as number,
					identifier: searchParams.get('identifier') || undefined,
					recurringPlanId: plan.id,
					cpfCnpj,
					creditCard: createPaymentData.creditCards?.[0].creditCard,
					address: finance?.isAddressRequired
						? {
								...address,
								number: address?.number?.trim()
						  }
						: undefined,
					phone: data.phone,
					email: data.email,
					installmentCount,
					affiliate_code:
						searchParams.get('affiliate_code') || undefined,
					customTrialDays: Number(customTrialDays) || undefined,
					click: searchParams.get('click') || undefined
				});
			}
		}

		if (!payment) throw new Error('Pagamento não encontrado.');

		return await confirmPaymentApi.mutateAsync({
			...createPaymentData,
			paymentId: payment?.id
		});
	};

	const handleCreditCard = (
		creditCards: CreditCard[],
		address: Address,
		isForeign: boolean,
		cpfCnpj?: string
	) => {
		return creditCards.map(
			async ({ creditCard, flatAmount, isNewCreditCard }) => {
				if (isNewCreditCard) {
					const expiration = creditCard.expiry.split('/');
					const expiryYear = expiration[isForeign ? 0 : 1];
					const expiryMonth = expiration[isForeign ? 1 : 0];

					try {
						const data = await tokenizeCreditCardApi.mutateAsync({
							type: 'card',
							hasAntifraud: finance?.isAntifraudEnabled ?? true,
							card: {
								number: creditCard.number.replaceAll(' ', ''),
								cvv: creditCard.cvv,
								exp_month: Number(expiryMonth),
								exp_year: Number(expiryYear),
								holder_name: creditCard.holderName
							}
						});

						return {
							percentageAmount:
								creditCards.length === 1 ? 100 : undefined,
							flatAmount:
								creditCards.length > 1 ? flatAmount : undefined,
							creditCard: {
								address: finance?.isAddressRequired
									? {
											postalcode:
												address.postalcode.replace(
													/\D/g,
													''
												),
											country: address.country,
											state: address.state,
											city: address.city,
											province: address.province,
											address: address.address,
											number: address.number?.trim()
									  }
									: undefined,
								cpfCnpj,
								expiryMonth,
								expiryYear,
								holderName: creditCard.holderName,
								number: data?.card.last_four_digits,
								token: data?.id
							}
						};
					} catch (e) {
						console.error(e);
						throw new Error(
							'Não foi possível registrar o cartão de crédito. Verifique os dados e tente novamente'
						);
					}
				} else {
					return {
						percentageAmount: flatAmount ? undefined : 100,
						flatAmount: flatAmount,
						creditCard: {
							id: creditCard.id
						}
					};
				}
			}
		);
	};

	return {
		handle
	};
};
