import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Col, Card } from 'react-bootstrap';

import { useCep } from '../../../../utils/useCep';
import { ConnectForm } from '../../../../shared/ConnectForm';
import { Inputs } from '../Form/inputs';
import { isValidState } from '../../../../utils/validators/state';

export const AddressForm = () => {
	const { setValue, getValues } = useFormContext<Inputs>();

	const handleFetchCep = async (cep: string) => {
		const { bairro, logradouro, localidade, uf } = await useCep.fetch(cep);

		setValue('address.province', bairro);
		setValue('address.city', localidade);
		setValue('address.state', uf);
		setValue('address.address', logradouro);
	};

	return (
		<ConnectForm>
			{({ register, control, formState: { errors } }) => (
				<>
					<Card.Title className="card-title mt-5 mt-md-8">
						<h3 className="card-label h4 text-gray-800">
							Endereço{' '}
							<span
								id="cc-address-label"
								className="text-muted pt-2 font-size-sm"
							>
								- Mesmo endereço do cartão de crédito
							</span>
						</h3>
					</Card.Title>
					<Row>
						<Col md={3} className="mb-5 pr-md-1">
							<TextField
								type="text"
								{...register('address.postalcode', {
									required: 'O campo de CEP é obrigatório!'
								})}
								placeholder="Digite apenas números"
								label="CEP*"
								error={!!errors.address?.postalcode}
								helperText={errors.address?.postalcode?.message}
								onBlur={(e) =>
									handleFetchCep(e.currentTarget.value)
								}
								className="w-100 pr-1"
							/>
						</Col>
						<Col className="mb-5 pl-md-1" md={9}>
							<Controller
								control={control}
								name="address.address"
								rules={{
									required:
										'O campo de endereço é obrigatório!'
								}}
								defaultValue=""
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										type="text"
										label="Endereço*"
										placeholder="Avenida Paulista"
										error={!!errors.address?.address}
										helperText={
											errors.address?.address?.message
										}
										className="w-100"
									/>
								)}
							/>
						</Col>

						<>
							<Col className="mb-5 pr-md-1" xs={4} lg={2}>
								<TextField
									type="text"
									{...register('address.number', {
										required:
											'O campo de número de endereço é obrigatório!',
										pattern: {
											message:
												'O campo de número de endereço deve conter somente números!',
											value: /[0-9]+/
										}
									})}
									placeholder="Digite apenas números"
									label="Número*"
									error={!!errors.address?.number}
									helperText={errors.address?.number?.message}
									className="w-100"
								/>
							</Col>
						</>

						<Col className={`mb-5 'px-md-1'`} md={5} xs={8}>
							<TextField
								type="text"
								label="Complemento"
								{...register('address.complement')}
								placeholder="AP 44 Bloco B"
								error={!!errors.address?.complement}
								helperText={errors.address?.complement?.message}
								className="w-100"
							/>
						</Col>

						<Col className="mb-5 pl-lg-1" md={5}>
							<Controller
								control={control}
								name="address.province"
								rules={{
									required: 'O campo de bairro é obrigatório!'
								}}
								defaultValue=""
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										type="text"
										label="Bairro*"
										placeholder="Pinheiros"
										error={!!errors.address?.province}
										helperText={
											errors.address?.province?.message
										}
										className="w-100"
									/>
								)}
							/>
						</Col>

						<Col md={9} className={`mb-5 pr-md-1`}>
							<Controller
								control={control}
								name="address.city"
								rules={{
									required: 'O campo de cidade é obrigatório!'
								}}
								defaultValue=""
								render={({ field: { value, onChange } }) => (
									<TextField
										type="text"
										{...register('address.city', {
											required:
												'O campo de cidade é obrigatório!'
										})}
										value={value}
										onChange={onChange}
										label="Cidade*"
										placeholder="São Paulo"
										error={!!errors.address?.city}
										helperText={
											errors.address?.city?.message
										}
										className="w-100"
									/>
								)}
							/>
						</Col>
						<TextField
							className="d-none"
							label="País"
							type="hidden"
							defaultValue="BR"
							{...register('address.country')}
						/>
						<Col md={3} className="mb-5 pl-md-1">
							<Controller
								control={control}
								name="address.state"
								rules={{
									required:
										'O campo de estado é obrigatório!',
									maxLength: {
										message:
											'O campo de estado deve conter no máximo de 2 caracteres!',
										value: 2
									},
									validate: (value) =>
										getValues('isForeign') ||
										isValidState(value)
											? true
											: 'Estado inválido'
								}}
								defaultValue=""
								render={({ field: { value, onChange } }) => (
									<TextField
										type="text"
										onChange={onChange}
										value={value}
										placeholder="SP"
										label="Estado*"
										error={!!errors.address?.state}
										helperText={
											errors.address?.state?.message
										}
										className="w-100"
									/>
								)}
							/>
						</Col>
					</Row>
				</>
			)}
		</ConnectForm>
	);
};
