import { isValidCnpj } from './cnpj';
import { isValidCpf } from './cpf';
import { Document } from './types/document';

export const createDocument = (value: string): Document => {
	if (isValidCpf(value))
		return {
			type: 'CPF',
			value
		};

	if (isValidCnpj(value))
		return {
			type: 'CNPJ',
			value
		};

	throw new Error('Documento informado é inválido.');
};

export const isValidDocument = (value: string): boolean => {
	try {
		createDocument(value);
		return true;
	} catch {
		return false;
	}
};
