import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';
import { ICheckout } from './types/checkout.type';

type IFindCheckoutByContent = [string, { id?: number }];

const findByContent: QueryFunction<
	ICheckout | undefined,
	IFindCheckoutByContent
> = async ({ queryKey }) => {
	const [_, { id }] = queryKey;

	if (!id) return;

	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<ICheckout>(`/checkouts/contents/${id}`);

	return response.data;
};

const findByPlan: QueryFunction<
	ICheckout | undefined,
	IFindCheckoutByContent
> = async ({ queryKey }) => {
	const [_, { id }] = queryKey;

	if (!id) return;

	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<ICheckout>(`/checkouts/plans/${id}`);

	return response.data;
};

export const checkoutsApi = {
	findByContent: {
		key: 'FIND_CHECKOUT_BY_CONTENT',
		fetch: findByContent
	},
	findByPlan: {
		key: 'FIND_CHECKOUT_BY_PLAN',
		fetch: findByPlan
	}
};
