import { apiFactory } from '../ApiFactory';

type Phone = {
	countryCode: string;
	areaCode: string;
	number: string;
};

export type CreateCustomerDto = {
	name?: string;
	email?: string;
	isForeign: boolean;
	cpfCnpj?: string;
	phones: {
		mobilePhone?: Phone;
		homePhone?: Phone;
	};
	authToken?: string;
};

const create = async ({ authToken, ...data }: CreateCustomerDto) =>
	(await apiFactory.getInstance().getCurseducaPayClient()).post(
		'/customers',
		data,
		{
			params: {
				token: authToken
			}
		}
	);

export const customersApi = {
	create
};
