import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import React, { forwardRef } from 'react';

import { ITextInputProps } from '../TextInputPropsInterface';

const CurrencyInput = forwardRef(
	({ onChange, ...props }: ITextInputProps, ref) => {
		const handleOnChange = (
			e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
			defaultOnChange:
				| React.ChangeEventHandler<
						HTMLTextAreaElement | HTMLInputElement
				  >
				| undefined
		) => {
			const value = e.target.value
				.replace(/\D/g, '')
				.replace(/(\d)(\d{2})$/, '$1,$2')
				.replace(/(?=(\d{3})+(\D))\B/g, '.');

			e.target.value = value;

			return defaultOnChange && defaultOnChange(e);
		};

		return (
			<TextField
				{...props}
				inputRef={ref}
				label="Valor no cartão"
				type="text"
				placeholder="0,00"
				onChange={(e) => handleOnChange(e, onChange)}
				inputMode="decimal"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">R$</InputAdornment>
					)
				}}
			/>
		);
	}
);

CurrencyInput.displayName = 'CurrencyInput';

export { CurrencyInput };
