import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';
import { tokenize } from './tokenize';
import { CreateCreditCardDto } from './types/CreateCreditCardDto';

export interface ICreditCard {
	id: number;
	memberId: number;
	token: string;
	number: string;
	flag: string;
	expiryYear: string;
	expiryMonth: string;
	isActive: boolean;
	inactivatedAt: string;
	createdAt: string;
	updatedAt: string;
}

const list: QueryFunction<{ data: ICreditCard[] } | null> = async () => {
	if (!localStorage.getItem('access_token')) return null;

	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<{
		data: ICreditCard[];
	}>('/credit-cards/');

	return response.data;
};

const create = async ({ authToken, ...data }: CreateCreditCardDto) =>
	(await apiFactory.getInstance().getCurseducaPayClient()).post(
		'/credit-cards',
		data,
		{
			params: { token: authToken }
		}
	);

export const creditCardsApi = {
	list: {
		key: 'LIST_CREDIT_CARDS',
		fetch: list
	},
	create: {
		key: 'CREATE_CREDIT_CARD',
		fetch: create
	},
	tokenize
};
