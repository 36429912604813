import { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TimerIcon } from '../../../../components/icons/TimerIcon';
import { ContentContext } from '../Context/ContentContext';

type TimeLeft = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
};

export const Counter = () => {
	const { content } = useContext(ContentContext);

	const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(null);

	useEffect(() => {
		if (!content || (!content.counterDate && !content.counterTime)) return;

		const counterTimeInSeconds = content.counterTime;
		const counterTimeInMiliseconds = counterTimeInSeconds
			? counterTimeInSeconds * 1000
			: 0;

		const counter = content.counterDate
			? new Date(content.counterDate)
			: new Date(Date.now() + counterTimeInMiliseconds);

		handleCounterUpdate(counter);
	}, [content]);

	const handleCounterUpdate = (counter: Date) => {
		const now = new Date();

		const isPast = +counter - +now < 0;
		if (isPast) return;

		const interval = window.setInterval(() => {
			const currentDate = new Date();

			const difference = +counter - +currentDate;

			if (difference < 0) return clearInterval(interval);

			setTimeLeft({
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / (1000 * 60)) % 60),
				seconds: Math.floor((difference / 1000) % 60)
			});
		}, 1000);
	};

	return (
		<>
			{timeLeft && (
				<Row className="bg-white justify-content-center py-2 w-100">
					<Col
						className="d-flex align-items-center justify-content-md-center justify-content-around"
						xs={12}
					>
						<div className="d-flex align-items-center mr-md-40">
							<span className="svg-icon svg-icon-primary svg-icon-3x">
								<TimerIcon />
							</span>
							<div>
								<h4 className="mb-0">Tempo Limitado!</h4>
								<span className="font-weight-bold">
									Preço promocional encerrará em:
								</span>
							</div>
						</div>

						<div className="d-flex">
							{!!timeLeft.days && (
								<>
									<div className="text-center">
										<span className="h3 text-primary">
											{timeLeft.days}
										</span>
										<br />
										<span className="font-size-sm font-weight-bold">
											Dias
										</span>
									</div>
									<span className="h3 text-primary mx-md-4 mx-2">
										:
									</span>
								</>
							)}
							{!!timeLeft.hours && (
								<>
									<div className="text-center">
										<span className="h3 text-primary">
											{timeLeft.hours}
										</span>
										<br />
										<span className="font-size-sm font-weight-bold">
											Horas
										</span>
									</div>
									<span className="h3 text-primary mx-md-4 mx-2">
										:
									</span>
								</>
							)}
							<div className="text-center">
								<span className="h3 text-primary">
									{timeLeft.minutes}
								</span>
								<br />
								<span className="font-size-sm font-weight-bold">
									Mins
								</span>
							</div>
							<span className="h3 text-primary mx-md-4 mx-2">
								:
							</span>
							<div className="text-center">
								<span className="h3 text-primary">
									{timeLeft.seconds}
								</span>
								<br />
								<span className="font-size-sm font-weight-bold">
									Segs
								</span>
							</div>
						</div>
					</Col>
				</Row>
			)}
		</>
	);
};
