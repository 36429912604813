import { QueryFunction } from 'react-query';
import { apiFactory } from '../ApiFactory';
import { ICoupon } from './interfaces/couponInterface';

type IGetByCodeAndContent = [string, { code?: string; contentId?: number }];

const getByCodeAndContent: QueryFunction<
	ICoupon,
	IGetByCodeAndContent
> = async ({ queryKey }) => {
	const [_, { code, contentId }] = queryKey;

	const response = await (
		await apiFactory.getInstance().getCurseducaPayClient()
	).get<ICoupon>(`/coupons/${code}/${contentId}`);

	return response.data;
};

export const couponsApi = {
	getByCodeAndContent: {
		key: 'GET_COUPON_BY_CODE_AND_CONTENT',
		fetch: getByCodeAndContent
	}
};
